import React, { useEffect } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Tooltip, RadioGroup, Radio } from "@mui/material";

// Import Styles and Image
import { ReportDownload } from '../../../assets/images/svgComponents/ImageComponent';

import ReportsStyles from "../ReportsStyles";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";

// import constants
import { surveyTypes, ReportsConstants} from "../../../config/constants";
import { dashboardConstant } from "../../../redux/constants/dashboardConstant";

//Import Actions
import { dashboardAction } from "../../../redux/actions";
import { baseAction } from "../../../redux/actions/baseAction";

const { request, success, failure } = baseAction;

function InsightDownloadReport(props) {
    // Define Dispatch
    const dispatch = useDispatch();

    // get props
    const { classes, reportType, dashboardDownload, isLoading  } = props;

    // Get Comment Reports List
    const { queryDLA, client_id } = useSelector(state => state.profile.user)
    const preference = useSelector((state) => state.admin.preference);
    const { totalresponse } = useSelector((state) => state.dashboard.dashboardData)
    const clientId = preference.client_id ? preference.client_id : '';
    const client_logo = useSelector((state) => state.client.getOne.logo);

    //set state for report name and checkbox
    const [includeDashboad, setIncludeDashboad] = React.useState(false);
    const [downloadOption, setDownloadOption] = useState(1);
    
    const { filterData } = useSelector(state => state.filter);

    useEffect(()=>{
        setDownloadOption(1)
    },[window.location.hash])


    //open close save popup
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setIncludeDashboad(false)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const actionTypes = [
        'drivers',
    ];

    const fetchDashboardDataRecursively = (actionTypes, index, callback = () => { }) => {
        if (index < actionTypes.length) {
            const actionType = actionTypes[index];
            const dashboardSurveys = filterData?.surveys || [];
            if (actionType === 'drivers') {
                if ((dashboardSurveys.length === 1 && dashboardSurveys?.[0]?.type !== surveyTypes.ENGAGEMENT) || (dashboardSurveys.length === 1 && dashboardSurveys?.[0]?.type === surveyTypes.ENGAGEMENT && !_.isEmpty(queryDLA)) ||
                (dashboardSurveys.length > 1)) {
                dispatch(dashboardAction.getDashboardActionData(() => {
                    fetchDashboardDataRecursively(actionTypes, index + 1, callback);
                }, false));
                } else {
                    fetchDashboardDataRecursively(actionTypes, index + 1, callback);
                }
            }else {
                fetchDashboardDataRecursively(actionTypes, index + 1, callback);
            }
        } else {
            callback();
            return;
        }
    };

    const handleDownload = () => {
        // dispatch(request(dashboardConstant.DOWNLOAD_DASHBOARD_REQUEST));
        if(downloadOption == 1) {
            // PDF download
        if (includeDashboad) {
            dispatch(request(dashboardConstant.DOWNLOAD_DASHBOARD_REQUEST));
            fetchDashboardDataRecursively(actionTypes, 0, () => {
            dispatch(dashboardAction.downloadDashboardReport(
                dashboardDownload ? true : includeDashboad,
                reportType,
                dashboardDownload,
                isLoading,
                preference,
                includeDashboad
            ));
            });
        } else {
            dispatch(dashboardAction.downloadDashboardReport(dashboardDownload ? true : includeDashboad, reportType, dashboardDownload, isLoading,preference,includeDashboad));
        }
        } else if(downloadOption == 2) {
            // PowerPoint download
        if (includeDashboad) {
            dispatch(request(dashboardConstant.DOWNLOAD_DASHBOARD_REQUEST));
            fetchDashboardDataRecursively(actionTypes, 0, () => {
            dispatch(dashboardAction.downloadDashboardReportPPT(
                dashboardDownload ? true : includeDashboad,
                reportType,
                dashboardDownload,
                isLoading,
                client_logo,
                preference,
                includeDashboad
            ));
            });
        } else {
            dispatch(dashboardAction.downloadDashboardReportPPT(dashboardDownload ? true : includeDashboad, reportType, dashboardDownload, isLoading,client_logo,preference,includeDashboad));
        }
        }
        setAnchorEl(null);
    }

    const hasMESurvey = (filterData.surveys || []).filter(s => s.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0;

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <React.Fragment>
            <Tooltip title={"Download"} arrow >
                <Grid className={classes.IconContainerSmall}>
                    <IconButton
                        className={classes.IconContainerSmall}
                        onClick={handleClick}
                        aria-describedby={id}
                        size="large">
                        <Grid className={classes.IconContainerSmall}>
                            {ReportDownload()}
                        </Grid>
                    </IconButton>
                </Grid>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                
                <Grid className={`${classes.download_popup} p-2`}>
                    <Grid item className="pb5">
                        <TypographyComponent variant="h5" className="txtBold">
                            {ReportsConstants.DOWNLOADREPORT}
                        </TypographyComponent>
                    </Grid>

                    <Grid item className="mb-2">
                        <TypographyComponent variant="body1">
                            {!hasMESurvey ?  ReportsConstants.DOWNLOADREPORT_TEXT : ReportsConstants.DOWNLOADREPORT_TEXT_ME}
                        </TypographyComponent>
                    </Grid>
                    {!hasMESurvey && 
                        <Grid className={classes.radioGroup}>
                            <RadioGroup
                                row
                                value={downloadOption}
                                onChange={(e)=>{setDownloadOption(e.target.value)}}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="PDF" />
                                <FormControlLabel value={2} control={<Radio />} label="PowerPoint" />
                            </RadioGroup>
                        </Grid>
                    }
                    <Grid item className="mb-2">
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            className="mt-2"
                        >
                            {
                                dashboardDownload || reportType !== "Dashboard Report Comment" &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={includeDashboad}
                                            onChange={(e) => {
                                                setIncludeDashboad(!includeDashboad);
                                            }}
                                            name="includeInDashboard"
                                            color="primary"
                                        />
                                    }
                                    label={ReportsConstants.INCLUDE_DASHBOARD}
                                />
                            }
                            <Grid>
                                <ButtonComponent
                                    color="primary"
                                    size="small"
                                    title={ReportsConstants.DOWNLOAD}
                                    pageClassName="mr-1"
                                    handleClick={handleDownload}
                                ></ButtonComponent>
                                <ButtonComponent
                                    color="default"
                                    size="small"
                                    title={ReportsConstants.CANCEL}
                                    handleClick={handleClose}
                                ></ButtonComponent>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </React.Fragment>
    );
}
// default props
InsightDownloadReport.defaultProps = {
    classes: {},
    reportType: 'Dashboard Report',
};

// prop types
InsightDownloadReport.propTypes = {
    classes: PropTypes.object,
    reportType: PropTypes.string,
};
export default withStyles(ReportsStyles)(InsightDownloadReport);
