const HotspotTableStyle = theme => ({
  TableHelpIcon: {
    "& .ml-1": {
      marginLeft: "0px !important",
      paddingRight: "5px !important",
      top: "-1px"
    }
  },
  centerAlignTxt: {
    textAlign: "center !important",
    fontSize: '13px',
    justifyContent: 'center'
  },

  leftAlignTxt: {
    textAlign: "left !important",
    fontSize: '13px'
  },
  cell1Width: {
    minWidth: "415px",
  },
  greyRow: {
    "& .sticky-table-cell": {
      background: "#d1d1d1",
      color: "#292929"
    }
  },
  KeyLogo: {
    position: "absolute",
    top: "30%",
    right: "20px"
  },
  tableheadercolor: {
    '&:hover span':{
      pointerEvents: 'none'
    },
    '& .sticky-table-cell': {
      '&:first-child': {
        cursor: 'context-menu',
      },
      cursor: 'pointer',
      '&:hover .MuiTableSortLabel-icon': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  hotspotViewbySelect: {
    '& > div': {
      paddingLeft: '4px !important',
    },
  },
  hoveredColumn: {
    backgroundColor: '#fffff',
  },
  tableContainer: {
    width: '100%',
    position: 'relative',
    '& .BaseTable__table-main': {
      border: 'none !important',
      outline: '0 !important',

    },
    '& .BaseTable__table-frozen-left':{
      boxShadow: '2px 0 0 0 #eeeeee'
    },
    '& .BaseTable__header-cell--sortable': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: '#ffffff !important',
        cursor: 'pointer'
      }
    },
    '& .MuiTableSortLabel-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active': {
      color: '#000', // Make active sort text more visible
    },
    '& .MuiTableSortLabel-icon': {
      marginTop: '8px',
      transition: 'transform 0.3s ease !important',
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      opacity: 1,
      visibility: 'visible',
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active[aria-sort="ascending"] .MuiTableSortLabel-icon': {
      transform: 'rotate(0deg) !important',
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active[aria-sort="descending"] .MuiTableSortLabel-icon': {
      transform: 'rotate(180deg) !important',
    },
    '& .BaseTable__table-main .BaseTable__row .BaseTable__row-cell, & .BaseTable__table-main .BaseTable__header-cell': {
      padding: '0',
    },
    '.BaseTable__header-row': {
      backgroundColor: '#ffffff !important',
    },
    '& .BaseTable__table-main .BaseTable__header .BaseTable__header-row': {
      backgroundColor: '#ffffff !important'
    },
    '& .BaseTable__table-frozen-left .BaseTable__header-row': {
      backgroundColor: '#ffffff !important',
      borderRight: '1px solid #e0e0e0 !important'
    },
    '& .BaseTable__table-frozen-left .BaseTable__header-cell, .BaseTable__table-frozen-left .BaseTable__row-cell':{
      borderRight: '1px solid #e0e0e0 !important'
    } ,
    '& .BaseTable__table-frozen-left .BaseTable__header-cell':{
      flexFlow: 'row wrap',
    alignItems: 'baseline'
    },
    
   

    
},
  staticRow: {
    backgroundColor: '#ffffff',  // default background
  },
  firstDataRow: {
    backgroundColor: '#d1d1d1 !important',
  },
  secondDataRow: {
    backgroundColor: '#ffffff !important',
  },
  headerCell: {
    padding: '0 !important',
    backgroundColor: '#ffffff !important',  // Force white background with !important
    '& > div': {  // Target all direct div children
      backgroundColor: '#ffffff !important'  // Ensure child divs are also white
    }
  },
  cell: {
    padding: '0 !important',
    display: "flex",
    alignItems: "center"
  },
});

export default HotspotTableStyle;
