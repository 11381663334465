/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";

// Import Styles and Image
import ReportsStyles from "./ReportsStyles";

// Import Reducer Constant
import { dashboardConstant } from '../../redux/constants';

// Import Constants
import { ReportsConstants, surveyTypes } from "../../config/constants"

// Import Child Containser
import Filter from "../Filter/Filter"
import HighLevelSummaryContent from "./Insight/HighLevelSummary/HighLevelSummaryContent"
import EIChart from "./Insight/EIChart/EIChart";
import YesNoChart from "./Insight/YesNo/YesNo";
import DropdownChart from "./Insight/Dropdown/Dropdown";
import Hotspot from "./Insight/Hotspot/hotspot";
import GridChart from "./Insight/GridChart/GridChart"
import TrendsOverTimeChart from "./Insight/TrendsOverTime/TrendsOverTime";
import CompetencySummaryChart from "./Insight/CompetencySummary/CompetencySummaryChart";
import DlaRole from "./DlaRole";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";


// Import Styles and Image
// import { RepeatGrid } from "../../assets/images/svgComponents/ImageComponent";

import gridImg from "../../assets/images/svg/repeat-grid.svg";

// Redux Actions
import { adminsAction, dashboardAction, updateStateReducerAction, filterAction, metadataAction } from "../../redux/actions"

function Insight(props) {

  // get props
  const { classes } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  // Set User Preference
  const { queryDLA, client_id } = useSelector(state => state.profile.user)
  const reload = useSelector(state => state.dashboard.reload)
  const isDataFiltersApplied = useSelector(state => state.dashboard.isDataFiltersApplied)
  const surveyData = useSelector(state => state.filter.allSurveys)
  const { commentinitialReload, hlsinitialReload, yesNoinitialReload, dropdowninitialReload, gridinitialReload, hotspotinitialReload, totinitialReload, EIinitialReload } = useSelector((state) => state.dashboard.initialReload);
  const preference = useSelector(state => state.admin.preference)
  const { tot } = preference
  const { filterData, surveys: surveyList } = useSelector(state => state.filter)
  const { _id } = client_id;

  // Spread Values
  const adminPreference = { ...preference }
  const { surveys, reportVisibility } = adminPreference
  const hasMESurvey = (filterData.surveys || []).filter(f => f.type === "Multi-rater feedback").length > 0

  // Default chart refresh
  const updatedChart = {
    '0': false,
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
    '6': false,
    '7': false,
    '8': false
  }

  // Set State
  const [chart, setChart] = useState(adminPreference.chartOrder && adminPreference.chartOrder.length ? adminPreference.chartOrder : ['0', '5', '4', '1', '3', '6', '2', '7','8']);

  const [hotspotReload, setHotspotReload] = useState(false);

  // TOT selected survey

  // Get Survey Details
  let { allSurveys } = useSelector(state => state.filter);

  allSurveys = allSurveys.filter((s) => s.type !== surveyTypes.MULTI_RATER_FEEDBACK)

  let totalSurveySelected = tot && tot.surveys && tot.surveys.length > 0 && tot.surveys[0] ? tot.surveys : preference.surveys;
  totalSurveySelected = (totalSurveySelected || []).map(m => m._id || m)
  const surveysSelected = (allSurveys || []).filter(m => totalSurveySelected.indexOf(`${m._id}`) >= 0);


  let selSurveyIds = [];
  let selSurveyNames = [];

  surveysSelected.map((val) => {
    selSurveyIds.push(val._id);
    selSurveyNames.push(val.name)
  })

  if (hasMESurvey && chart.indexOf('7') === -1) {
    chart.push('7')
  }

  // Reset Chart Load
  useEffect(() => {
    let resetChartRefresh = { ...updatedChart }
    Object.keys(resetChartRefresh).map(chart => {
      if (chart === '1') {
        if (!commentinitialReload) {
          if (chart in (reportVisibility || {})) {
            resetChartRefresh = {
              ...resetChartRefresh,
              [chart]: true
            }
          }
        }
      } else {
        resetChartRefresh = {
          ...resetChartRefresh,
          [chart]: true
        }
      }
    })
    dispatch(updateStateReducerAction.update(dashboardConstant.CHARTS_INSINGT_RELOAD, {
      ...resetChartRefresh
    }))

    loadInitialChartValue(true)

  }, [dispatch, _id])

  const executeTasks = (index, tasks) => {
    // var task = tasks[index];
    // dispatch(task(() => {
    //   if (index + 1 < tasks.length) {
    //     executeTasks(index + 1, tasks)
    //   }
    // }));

    // Commented one by one api call logic
    tasks.forEach((task) => {
      dispatch(task(() => { }));
    });
  }

  const hasPulse = (surveys || []).filter((val) => (val.frequency === "Pulse")) || []

  // Load Initial Chart
  const loadCompletedChart = () => {
    const tasks = []

    // if (!hlsinitialReload && !commentinitialReload && !yesNoinitialReload && !gridinitialReload && !hotspotinitialReload && !EIinitialReload && !totinitialReload) {
    //   dispatch(dashboardAction.resetChartData("all"))
    // }

    // KeyDriver Data
    if ((reportVisibility[1] || reportVisibility[4]) && (!queryDLA || queryDLA === "")) {
      dispatch(dashboardAction.getKeyDriverData(() => { }, false))
    }

    // Get High Level Summary Data
    if (reportVisibility[0] && !hlsinitialReload) {
      tasks.push(dashboardAction.getHighLevelSummaryData)
      // tasks.push(dashboardAction.getHistoricalData)
    }

    // Get Engagement Index
    if (reportVisibility[5] && !EIinitialReload) {
      dispatch(dashboardAction.EIOverall())
    }

    if (reportVisibility[2] && !yesNoinitialReload) {
      tasks.push(dashboardAction.getYesNoData)
    }

    if (reportVisibility[8] && !dropdowninitialReload) {
      tasks.push(dashboardAction.getDropdownData)
    }

    // Get Grid Chart Data
    if (reportVisibility[3] && hasPulse.length === 0 && !gridinitialReload) {
      tasks.push(dashboardAction.getGridChartData)
    }

    //Get Hotspot Data
    if (reportVisibility[4] && !hotspotinitialReload) {
      tasks.push(dashboardAction.getHotspotData)

    }

    if (reportVisibility[6] && !totinitialReload) {
      dispatch(dashboardAction.getTrendsOverTimeData(() => {
        dispatch(dashboardAction.updateTrendsOverTimePreference(surveysSelected))
      }))
    }
    if (tasks.length > 0) {
      executeTasks(0, tasks)
    }

  }

  // Load Initial Chart
  const loadInitialChartValue = (initialReload) => {

    // get demographics dropdown
    // dispatch(metadataAction.getMetadataWithRGI())
    dispatch(filterAction.getDemographicsDropdown({
      metadata: [],
      surveys: preference.surveys,
      date: preference.date
    }))

    if (!hasMESurvey) {
      if (initialReload) {
        loadCompletedChart()
      }
    } else {
      // get competency chart
      if (reportVisibility[7] || hasMESurvey) {
        dispatch(dashboardAction.getCompetencySummary());
      }
    }

  }

  // Set Chart Visible
  const hideCharts = {
    hls: false,
    yesno: false,
    dropdown: false,
    grid: false,
    openEnded: false,
    pulseType: false,
    engagement: false,
    competency: false
  }


  if (surveys && surveys.length) {
    surveys.map(item => {
      if (item?.['frequency'] === "Pulse") {
        hideCharts.pulseType = true
      }
      if (item?.['type'] === "Multi-rater feedback") {
        hideCharts.competency = true
      }
      if (item.type && item.type === "Engagement" && item?.['engagement'] > 0) {
        hideCharts.engagement = true
      }
      if (item?.['yesno']) {
        hideCharts.yesno = true
      }
      if (item?.['dropdown']) {
        hideCharts.dropdown = true
      }
      if (item?.['matrix']) {
        hideCharts.grid = true
      }
      if (item?.['singleselect']) {
        hideCharts.hls = true
      }
      if (item?.['openended']) {
        hideCharts.openEnded = true
      }
    })
  }

  /**
   * Handle Close
   */
  const handleClose = (chart) => {
    if (chart === '1') {
      dispatch(updateStateReducerAction.update(dashboardConstant.RESET_COMMENT_CHART))
    }
    delete adminPreference.reportVisibility[chart]

    // Update User Preference
    dispatch(adminsAction.updateReportPreference({
      ...adminPreference,
      reportVisibility: {
        ...adminPreference.reportVisibility
      }
    }))

    // Default Chart Reload Options
    let reloadChart = {
      '0': true,
      '1': true,
      '2': true,
      '3': true,
      '4': true,
      '5': true,
      '6': true,
      '7': true,
      '8': true
    };

    // set visible charts refresh to false
    Object.keys(reload).map(chart => {
      if (chart in adminPreference.reportVisibility) {
        reloadChart = {
          ...reloadChart,
          [chart]: false
        }
      }
    })

    dispatch(updateStateReducerAction.update(dashboardConstant.CHARTS_INSINGT_RELOAD, {
      ...reloadChart
    }))
  }

  // prevent chart rendering while drag
  const resetChartState = () => {
    dispatch(updateStateReducerAction.update(dashboardConstant.CHARTS_INSINGT_RELOAD, {
      ...updatedChart
    }))
  }

  // Get Data Based On Filter
  const filterSaveEvent = (data) => {
    let resetChartRefresh = { ...updatedChart }
    Object.keys(resetChartRefresh).map(chart => {
      if (chart in adminPreference.reportVisibility) {
        resetChartRefresh = {
          ...resetChartRefresh,
          [chart]: true
        }
      }
    })

    // Empty Comment Analysis Questions When Filter Changed and Save
    // dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_COMMENT_QUESTION, {}))

    // Active Loading to reload all charts again
    dispatch(updateStateReducerAction.update(dashboardConstant.CHARTS_INSINGT_RELOAD, {
      ...resetChartRefresh
    }))

    loadInitialChartValue(false)
  }

  // Update User Report Preferences
  const updateReportPreference = (data, chartNeedToReload, needReload = true) => {
    dispatch(adminsAction.updateReportPreference(data, () => {
      rlChart(chartNeedToReload, needReload)
    }));
  }

  const rlChart = (chartNeedToReload, needReload) => {
    if (chartNeedToReload === "4") {
      setHotspotReload(true)
    }

    if (needReload) {
      // API call for HLS chart for updating cache data
      if (chartNeedToReload === "0") {
        dispatch(dashboardAction.getHighLevelSummaryData(() => {
          // dispatch(dashboardAction.getHistoricalData())
        }))
      }

      // API call for HLS chart for updating cache data
      if (chartNeedToReload === "4") {
        dispatch(dashboardAction.getHotspotData());
      }
      if (chartNeedToReload === "5") {
        dispatch(dashboardAction.EIWithRgi());
      }

      if (chartNeedToReload === "2") {
        dispatch(dashboardAction.getYesNoData());
      }
    }


    // if (needReload) {
    //   Object.keys(reload).map(d => {
    //     if (chartNeedToReload == d) {

    // console.log("reportVisibility",reportVisibility, "===", chart ,"line : 352",reload);
    //       dispatch(updateStateReducerAction.update(dashboardConstant.CHARTS_INSINGT_RELOAD, {
    //         ...reload,
    //         [d]: true
    //       }))
    //     }
    //   })
    // }
  }

  // Stop Chart Reload
  const stopChartReload = (chartNeedToStopReload) => {
    dispatch(updateStateReducerAction.update(dashboardConstant.CHARTS_INSINGT_RELOAD, {
      ...reload,
      [chartNeedToStopReload]: false
    }))
  }

  const updateHotspotReload = () => {
    setHotspotReload(false)
  }

  /**
* Drage Handle
*/
  const DragHandle = SortableHandle(() => (
    <img src={gridImg} className="cursor-move pt5 icon-hover" alt={"Menu"} draggable={false} />
  ));

  const matchedFilters = (prevData, currentData) => {
    const prevDataCondition = prevData && Array.isArray(prevData) && prevData.length > 0;
    const currentDataCondition = currentData && Array.isArray(currentData) && currentData.length > 0;

    if (!prevDataCondition || !currentDataCondition) {
      return null;
    }

    return _.intersection(prevData, currentData);
  };

  const checkFiltersApplied = (checkObj, checkObjParser, type = '') => {
    if (type === '') {
      return false;
    }

    const isCheckObj = checkObj && _.isObject(checkObj) && Object.keys(checkObj).length > 0;
    const isFiltersApplied = checkObj?.['filtersApplied'] && _.isObject(checkObj?.['filtersApplied']) && Object.keys(checkObj?.['filtersApplied']).length > 0;

    if (!isCheckObj || !isFiltersApplied) {
      return false;
    }

    const filtersAppliedType = Object.keys(checkObj?.['filtersApplied']).length === 1 ? Object.keys(checkObj?.['filtersApplied'])?.[0] : null;

    if (!filtersAppliedType) {
      return false;
    }

    const isCurrentObj = type === 'hotspot' ? checkObj?.[checkObjParser] : checkObj?.[checkObjParser]?.[filtersAppliedType === 'keyDrivers' ? 'question' : filtersAppliedType] || {};
    const isCurrentData = type === 'hotspot' ?
      isCurrentObj && Array.isArray(isCurrentObj) && isCurrentObj.length > 0
      : isCurrentObj && _.isObject(isCurrentObj) && Object.keys(isCurrentObj).length > 0;
    const isFiltersAppliedObj = checkObj?.['filtersApplied']?.[filtersAppliedType] || [];
    const isFiltersAppliedData = isFiltersAppliedObj && _.isObject(isFiltersAppliedObj) && Object.keys(isFiltersAppliedObj).length > 0;

    const mapFields = {
      hls: {
        question: 'questionText',
        keyDrivers: 'questionText'
      }
    }

    if (Boolean(isCurrentData && isFiltersAppliedData)) {
      const selectedRg = preference?.['hls']?.['rg'] ? preference?.['hls']?.['rg'] : 'tenure_groupings';
      let mappedCurrentData;
      if (type === 'hls') {
        const currentObj = filtersAppliedType === 'demographic' ? isCurrentObj?.[selectedRg] : isCurrentObj;

        mappedCurrentData = filtersAppliedType === 'question' || filtersAppliedType === 'keyDrivers' ?
          _.map(currentObj, mapFields?.[type]?.[filtersAppliedType]) :
          Object.keys(currentObj || {});
      }

      if (type === 'hotspot') {
        const currentParser = filtersAppliedType === 'category' && isCurrentObj?.[2]?.['catName'] ? 'catName' : '_id';

        mappedCurrentData = isCurrentObj.reduce((acc, curr, index) => {
          if (["Number of Respondents (N)", "Overall % Favorable"].indexOf(curr?.[currentParser]) === -1) {
            acc = [...acc, curr?.[currentParser]];
          }

          return acc;
        }, [])
      }

      return matchedFilters(mappedCurrentData || [], isFiltersAppliedObj || []);
    }

    return null;
  };

  const filtersAppliedChange = (chartType, chartData, chartDataParser) => {
    const isChartCheck = checkFiltersApplied(chartData, chartDataParser, chartType)
    const filtersAppliedType = Object.keys(chartData?.['filtersApplied']).length === 1 ? Object.keys(chartData?.['filtersApplied'])?.[0] : null;

    if (isDataFiltersApplied) {
      if (
        (
          (chartType === 'hls' && filtersAppliedType === 'keyDrivers') &&
          (filterData?.['surveys'].length > 1 ||
            (filterData?.['surveys'].length === 1 && adminPreference?.['hls']?.['type'] === 'keyDrivers')
          )
        ) ||
        (
          chartType === 'hotspot' && filtersAppliedType === 'keyDriver' &&
          (filterData.surveys.length > 1 ||
            (filterData?.['surveys'].length === 1 && adminPreference?.['hotspot']?.['type'] === 'keyDriver')
          )
        )
      ) {
        dispatch(dashboardAction.applyFilters(
          () => { },
          true,
          chartType,
          {}
        ))
      } else {
        if (isChartCheck && Array.isArray(isChartCheck) && isChartCheck.length > 0 && filtersAppliedType) {
          dispatch(dashboardAction.applyFilters(
            () => { },
            true,
            chartType,
            {
              [filtersAppliedType]: isChartCheck
            }
          ))
        } else {
          const isChartCheckEmpty = Array.isArray(isChartCheck) && JSON.stringify(isChartCheck) === '[]';
          if (
            chartType.toLowerCase() === 'hotspot' ? JSON.stringify(chartData?.['chartData']) === '[]' ? false : isChartCheckEmpty : isChartCheckEmpty
          ) {
            dispatch(dashboardAction.applyFilters(
              () => { },
              true,
              chartType,
              {}
            ))
          } else {
            dashboardAction.resetDataFiltersAppliedFlag();
          }
        }
      }
    }
  }

  const SortableItem = SortableElement(props => {
    const { value: item } = props;
    const hiddenJsx = <div></div>
    switch (item) {
      case '0':
        if (reportVisibility && reportVisibility[0] && hideCharts.hls && !hasMESurvey)
          return <HighLevelSummaryContent key={item}
            preference={adminPreference} allSurveys={surveyData}
            updateReportPreference={updateReportPreference}
            isUpdate={reload['0']} stopChartReload={stopChartReload}
            DragHandle={DragHandle} classes={classes} handleClose={handleClose} rlChart={rlChart} filtersAppliedChange={filtersAppliedChange} />
        else
          return hiddenJsx
      case '1':
        return hiddenJsx
      case '2':
        if (reportVisibility && reportVisibility[2] && !hideCharts.pulseType && hideCharts.yesno && !hasMESurvey)
          return <YesNoChart preference={adminPreference} updateReportPreference={updateReportPreference} isUpdate={reload['2']} stopChartReload={stopChartReload} DragHandle={DragHandle} classes={classes} handleClose={handleClose} />
        else
          return hiddenJsx
      case '3':
        if (reportVisibility && reportVisibility[3] && !hideCharts.pulseType && hideCharts.grid && !hasMESurvey)
          return <GridChart preference={adminPreference} updateReportPreference={updateReportPreference} isUpdate={reload['3']} stopChartReload={stopChartReload} DragHandle={DragHandle} classes={classes} handleClose={handleClose} />
        else
          return hiddenJsx
      case '4':
        if (reportVisibility && reportVisibility[4] && !hideCharts.pulseType && hideCharts.hls && !hasMESurvey)
          return <Hotspot preference={adminPreference} updateReportPreference={updateReportPreference} stopChartReload={stopChartReload} updateHotspotReload={updateHotspotReload} hotspotReload={hotspotReload} isUpdate={reload['4']} DragHandle={DragHandle} classes={classes} handleClose={handleClose} rlChart={rlChart} filtersAppliedChange={filtersAppliedChange} />
        else
          return hiddenJsx
      case '5':
        if (reportVisibility && reportVisibility[5] && hideCharts.engagement && !hideCharts.pulseType && !hasMESurvey)
          return <EIChart preference={adminPreference} updateReportPreference={updateReportPreference} stopChartReload={stopChartReload} isUpdate={reload['5']} DragHandle={DragHandle} classes={classes} handleClose={handleClose} />
        else
          return hiddenJsx
      case '6':
        if (reportVisibility && reportVisibility[6] && hideCharts.hls && !hasMESurvey)
          return (
            <TrendsOverTimeChart preference={adminPreference} isUpdate={reload['6']} stopChartReload={stopChartReload} DragHandle={DragHandle} classes={classes} handleClose={handleClose} />)
        else
          return hiddenJsx
      case '7':
        if (reportVisibility && reportVisibility?.[7] && hideCharts?.competency && hasMESurvey)
          return (
            <CompetencySummaryChart
              preference={adminPreference}
              isUpdate={reload['7']}
              stopChartReload={stopChartReload}
              DragHandle={DragHandle}
              classes={classes}
              // handleClose={handleClose}
              updateReportPreference={updateReportPreference}
            />
          )
        else
          return hiddenJsx
      case '8':
        if (reportVisibility && reportVisibility[8] && !hideCharts.pulseType && hideCharts.dropdown && !hasMESurvey)
          return <DropdownChart preference={adminPreference} updateReportPreference={updateReportPreference} isUpdate={reload['8']} stopChartReload={stopChartReload} DragHandle={DragHandle} classes={classes} handleClose={handleClose} />
        else
          return hiddenJsx
      default:
        return hiddenJsx
    }
  });

  /**
     * Sortable List
     */
  const SortableList = SortableContainer(props => {
    const { items, ...restProps } = props;
    return (
      <Grid item xs={12}>
        <Grid container spacing={1}>
        {items.map((item, index) => (
            <SortableItem
              key={`item-${item}`}
              index={index}
              value={item}
              {...restProps}
            />
          ))
          }

        </Grid>
      </Grid>
    )
  });

  /**
   * Handle Sort End Event
   * @param {*} e
   */
  const onSortEnd = (e) => {
    const { oldIndex, newIndex } = e;

    // Set Reload Chart
    resetChartState()

    // Set Chart Order
    setChart(arrayMove(chart, oldIndex, newIndex))

    // Update Report Preference
    dispatch(adminsAction.updateReportPreference({
      ...adminPreference,
      chartOrder: arrayMove(chart, oldIndex, newIndex)
    }))
  };

  return (
    <React.Fragment>
      {reportVisibility &&
        <Grid container spacing={3}>
          {queryDLA && queryDLA !== " " &&
            <DlaRole />
          }

          <SortableList
            axis='y'
            useDragHandle
            items={chart}
            onSortEnd={onSortEnd} />
        </Grid>
      }

      {/* Filters */}
      <Filter
        classes={classes}
        filterSaveEvent={filterSaveEvent}
        className={(reportVisibility && Object.keys(reportVisibility).length !== Object.keys(ReportsConstants.CHARTS).length) || typeof reportVisibility === 'undefined' ? 'posAbsDataFilterInsightIconWithMoreButton' : 'posAbsDataFilterInsightIcon'}
        needIcon={false}
      />
    </React.Fragment>
  );
}

// default props
Insight.defaultProps = {
  classes: {}
};

// prop types
Insight.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ReportsStyles)(Insight);
