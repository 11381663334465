import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import { VariableSizeList as VList } from 'react-window';

// Images
import PlusImg from '../../../assets/images/svg/plus_grey.svg';
import MinusImg from '../../../assets/images/svg/minus_blue.svg';

//Import Actions
import { updateStateReducerAction } from '../../../redux/actions'

// Import Style
import FilterStyles from "../FilterStyles";

// Import Reducer Constant
import { filterConstant } from '../../../redux/constants';

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";

function DemographicFilterItem(props) {

    // props
    const { classes, demographicData, demographicKey, metadata, handlePanelChange, expanded } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    // Get filter Data
    const { filterData, demographicItems, loading } = useSelector(state => state.filter)

    // Get Demographic Name
    let selData = metadata?.filter?.(e => e.key === demographicKey)
    const demographicName = selData?.[0] && selData?.[0]?.value ? selData?.[0]?.value : "";

    /**
     * Handle Change
     * @param {*} e 
     * @param {*} item 
     */
    const handleChange = (e, item) => {
        if (e.target.checked) {
            if (filterData.demographics[demographicKey]) {
                filterData.demographics[demographicKey].push(item._id)
            } else {
                filterData.demographics = {
                    ...filterData.demographics,
                    [demographicKey]: [item._id]
                }
            }
        } else {
            filterData.demographics[demographicKey].splice(filterData.demographics[demographicKey].indexOf(item._id), 1)
        }

        dispatch(updateStateReducerAction.update(filterConstant.UPDATE_FILTER_SUCCESS, filterData))
    }

    const Row = ({ index, style }) => {
        const item = demographicItems?.[demographicKey]?.[index];
        return (
            <div style={style}>
                <Grid item xs={12} className={classes?.expansionDetail}>
                    <Grid item className={classes?.demographyCheckbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={item?._id}
                                    color="primary"
                                    checked={filterData?.demographics && filterData?.demographics?.[demographicKey] && filterData?.demographics?.[demographicKey]?.includes?.(item?._id) ? true : false}
                                    onChange={e => handleChange(e, item)}
                                />
                            }

                            label={`${item?._id && item?._id?.length > 35
                                ? `${item?._id?.slice(0, 35)}...`
                                : item?._id} (${item?.data})`}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }

    /**
     * Show Loading
     */
    const showLoading = () => {
        return <Grid item xs={12} className={`${classes.expansionDetail} txtcenter`}>
            <CircularProgress size={20} />
        </Grid>
    }

    const itemSize = (index) => {
        const baseHeight = 40;
        return Math?.max?.(30, Math?.min?.(60, baseHeight));
    };


    return (

        <Accordion
            classes={{
                root: classes.peaccordion_nocolor_demography
            }}

            expanded={expanded === demographicKey}
            onChange={() => handlePanelChange(demographicKey)}>
            <AccordionSummary
                aria-controls={`${demographicKey}_controll`}
                id={`${demographicKey}_id`}>
                <TypographyComponent variant={"h6"}
                    className={`${""} ${expanded === demographicKey ? "panel-active" : ""} `}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img className={`${classes?.expansionPlus} expansionPlus pr-1`} src={PlusImg} />
                        <img className={`${classes?.expansionMinus} expansionMinus pr-1`} src={MinusImg} />
                        <span>{`${demographicName && demographicName?.length > 35
                            ? `${demographicName?.slice(0, 35)}...`
                            : demographicName} (${demographicData})`}</span>


                    </div>
                </TypographyComponent>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '5px 5px' }}>
                {loading ? showLoading() : (
                    demographicItems[demographicKey]?.length > 0 ? (
                        <VList
                            height={Math?.min?.(demographicItems?.[demographicKey]?.length * 40, 300)}
                            itemCount={demographicItems?.[demographicKey]?.length}
                            itemSize={itemSize}
                            width="100%"
                        >
                            {Row}
                        </VList>
                    ) : (
                        <TypographyComponent title="No items found!" variant="inherit" />
                    )
                )}
            </AccordionDetails>
        </Accordion>
    )
}
// default props
DemographicFilterItem.defaultProps = {
    classes: {},
    demographicData: "",
    demographicKey: "",
    preference: {},
    metadata: [],
    searchKey: '',
    handlePanelChange: () => { },
    expanded: null
};

// prop types
DemographicFilterItem.propTypes = {
    classes: PropTypes.object,
    demographicData: PropTypes.string,
    demographicKey: PropTypes.string,
    preference: PropTypes.object,
    metadata: PropTypes.array,
    searchKey: PropTypes.string
};
export default withStyles(FilterStyles)(DemographicFilterItem); 