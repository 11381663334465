import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import withStyles from '@mui/styles/withStyles';
import { TableSortLabel } from '@mui/material';

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import BaseTable, { AutoResizer, Column } from 'react-base-table';
import 'react-base-table/styles.css';

import _ from "lodash";
import { keyDriver, Filter } from "../../../../assets/images/svgComponents/ImageComponent"
import InformationComponent from "../../../../components/InformationComponent/InformationComponent"
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent"
import MultiSelectChecboxWithSearch from "../../../../components/MultiSelectComponent/MultiSelectCheckboxWithSearch";
import HotspotTableStyle from "./HotspotTableStyle";
import { addEllipsis, generateKey } from "../../../../helpers";

// Constants
const PAGE_SIZE = 50;
const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 250;
const FIXED_COLUMN_WIDTHS = {
  text: 300,
  Overall: 55,
  Category: 150,
  'Key Driver': 50
};

// Add a style block to hide BaseTable's default sort icons
const HideSortIconStyle = () => (
  <style>
    {`
      .BaseTable__sort-indicator {
        display: none !important;
      }
        .BaseTable__header-cell .MuiTableSortLabel-icon {
        opacity: 0;
        transition: opacity 0.2s ease;
      }
      
      .BaseTable__header-cell:hover .MuiTableSortLabel-icon {
        opacity: 1 !important;
      }

      .BaseTable__header-cell .MuiTableSortLabel-active .MuiTableSortLabel-icon {
        opacity: 1 !important;
      }
      
    `}
  </style>
);

// Main Component
const HotspotStickyTable = ({
  classes,
  selectedType,
  updateSelectedType,
  header,
  staticRow,
  rowOrder,
  colorRange,
  sortingData,
  leftAlignCells,
  handleSorting,
  zoom,
  keydriver,
  tableData,
  applyFilters,
  filtersApplied,
  fetchMoreData,
}) => {

  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [columnWidths, setColumnWidths] = useState(() =>
    header.reduce((acc, item) => {
      acc[item] = FIXED_COLUMN_WIDTHS[item] || 50;
      return acc;
    }, {})
  );

  const [displayData, setDisplayData] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadedAll, setLoadedAll] = useState(false);
  const [viewByAnchor, setViewByAnchor] = useState(null);
  const [viewByCategoryAnchor, setViewByCategoryAnchor] = useState(null);
  const [triggerType, setTriggerType] = useState(
    _.isEmpty(filtersApplied) ? '' : filtersApplied?.[selectedType] ? selectedType : 'category'
  );
  const [filteredValues, setFilteredValues] = useState(
    _.isEmpty(filtersApplied) ? [] : filtersApplied?.[selectedType] || filtersApplied?.['category'] || []
  );
  const tableRef = useRef(null);
  const overallIndex = header.findIndex(item => item === "Overall");

  // Transform sortingData into sortState format that BaseTable expects
  const sortState = useMemo(() => {
    return Object.entries(sortingData || {}).reduce((acc, [key, order]) => {
      acc[key] = order;
      return acc;
    }, {});
  }, [sortingData]);

  // Data Transformation
  const transformData = useCallback((data) => {
    let filteredData = data
    if (!_.isEmpty(triggerType) && !_.isEmpty(filtersApplied)) {
      const questionKeyDriverCondition = ['question', 'keyDriver'].indexOf(triggerType) !== -1;
      const selectedCategoryCondition = selectedType === 'category';

      const localHeaderColumnName = selectedCategoryCondition ? '_id' : questionKeyDriverCondition ? '_id' : 'catName';

      const filterDataBasedOnType = getInitialFilteredData(tableData, triggerType);
      const isAllSelected = _.isEqual(_.orderBy(Object.keys(filterDataBasedOnType || {})), _.orderBy((triggerType || [])));
      let triggerTypeData = filtersApplied[triggerType] || ''
      if (!isAllSelected && triggerTypeData) {
        filteredData = tableData.filter(item => triggerTypeData.indexOf(item[localHeaderColumnName]) !== -1);
      }
    }
    return filteredData.map((item, idx) => {
      const rowData = rowOrder.reduce((acc, key, colIdx) => {
        acc[key] = item[key];
        return acc;
      }, {});

      return {
        ...rowData,
        _id: item._id || `row-${idx}`,
        id: generateKey(item?.baseQuesId || item?._id),
      };
    });
  }, [header, rowOrder]);

  const staticData = useCallback(() => {
    return staticRow.map((row, idx) => ({
      ...rowOrder.reduce((acc, key, colIdx) => {
        acc[header[colIdx]] = row[key];
        return acc;
      }, {}),
      id: `static-${idx}`,
    }));
  }, [staticRow, rowOrder, header]);




  // Initial Data Load
  useEffect(() => {
    // if (_.isEmpty(filtersApplied)){
    const initialData = transformData(tableData.slice(0, PAGE_SIZE));
    setDisplayData([...staticRow, ...initialData]);
    setLoadedAll(tableData.length <= PAGE_SIZE);
  // }
  }, [tableData, staticData, transformData]);
  


  // Column Configuration
  const columns = useCallback(() => {
    return header.map((item, index) => ({
      key: `${item}-${index}`,
      dataKey: item,
      title: item,
      width: columnWidths[item] || FIXED_COLUMN_WIDTHS[item] || 50,
      minWidth: FIXED_COLUMN_WIDTHS[item] || 50,
      flexGrow: item === "text" ? 1 : 0,
      frozen: index <= overallIndex ? Column.FrozenDirection.LEFT : undefined,
      resizable: index <= overallIndex,
      sortable: true,
      headerRenderer: ({ column }) => {
        const { title } = column;
        const sortDirection = sortingData[title] || false;
        let content;
        if (title === "text") {
          content = (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-around', // Changed from 'center' to 'space-between'
              alignItems: 'flex-end', 
              height: '100%', 
              padding: '0 8px 24px',
              backgroundColor: '#ffffff',
              width: '100%'  // Ensure full width
            }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip arrow title={filtersApplied?.[selectedType]?.length ? 'Filters Applied' : 'Apply Filters'}>
                <IconButton
                  className={`icon-hover ${!_.isEmpty(filtersApplied) && filtersApplied?.[selectedType]?.length ? 'active' : ''}`}
                  onClick={(e) => setViewByAnchor(e.currentTarget)}
                >
                  {Filter()}
                </IconButton>
              </Tooltip>
              <Select
                className={`${classes.chartHeadings} ${classes.hotspotViewbySelect} txtBold fontMontserrat disableBorder`}
                value={keydriver.length ? selectedType : (selectedType === "keyDriver" ? "question" : selectedType)}
                disableUnderline
                onChange={(e) => updateSelectedType(e.target.value)}
              >
                <MenuItem value="category">View By Category</MenuItem>
                <MenuItem value="question">View By Question</MenuItem>
                {keydriver.length > 0 && <MenuItem value="keyDriver">View By Key Drivers</MenuItem>}
              </Select>
              {selectedType === "keyDriver" && (
                <InformationComponent>
                  <TypographyComponent variant="tooltipbody">
                    Items marked with a blue key refer to your top engagement drivers.{' '}
                    <a href="https://20709424.hs-sites.com/understanding-key-drivers" target="_blank" rel="noopener noreferrer" style={{ color: "#54c0fd" }}>
                      Click here
                    </a> to learn more.
                  </TypographyComponent>
                </InformationComponent>
              )}
              </div>
              {/* Empty div to reserve space for sort icon */}
            </div>
          );
        } else if (title === "Category") {
          content = (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', // Changed from 'center' to 'space-between'
              alignItems: 'flex-end', 
              height: '100%', 
              padding: '0 8px 24px',
              backgroundColor: '#ffffff',
              width: '100%'  // Ensure full width
            }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip arrow title={(filtersApplied?.['category'] && filtersApplied?.['category'].length > 0) ? 'Filters Applied' : 'Apply Filters'}>
                <IconButton
                  className={`icon-hover ${filtersApplied?.['category'] && filtersApplied?.['category']?.length ? 'active' : ''}`}
                  onClick={(e) => setViewByCategoryAnchor(e.currentTarget)}
                >
                  {Filter()}
                </IconButton>
              </Tooltip>
              <span className={`${classes.chartHeadings} ${classes.hotspotViewbySelect} txtBold fontMontserrat disableBorder`}>{item}</span>
            </div>
            {/* Empty div to reserve space for sort icon */}
            </div>
          );
        } else if (title === "Key Driver") {
          content = (
            <div className="txtBold fontMontserrat" style={{ 
              writingMode: 'sideways-lr',
                display: 'flex',
                justifyContent: 'flex-end', // Changed from 'flex-end' to 'space-between'
                alignItems: 'center',
                flexDirection: 'row-reverse',
                // gap: '8px',
                height: '100%',
                padding: '4px 0 4px',
                position: 'relative',
                backgroundColor: '#ffffff',
              width: '100%'  // Ensure full width
            }}>
              <span>{item}</span>
              {/* Empty div to reserve space for sort icon */}
            </div>
          );
        } else {
          const displayText = title.length > 25 ? `${title.slice(0, 25)}...` : title;
          content = (
            <div className={`${classes.chartHeadings} ${classes.hotspotViewbySelect} txtBold fontMontserrat disableBorder`} 
              style={{
                writingMode: 'sideways-lr',
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '8px',
                height: '100%',
                padding: '4px 0 0px',
                justifyContent: 'flex-end',
                position: 'relative',
                backgroundColor: '#ffffff'  // Ensure white background
              }}>
              <Tooltip title={title} placement="top">
              <div >
                <TypographyComponent variant="h6">
                  {displayText}
                </TypographyComponent>
              </div>
              </Tooltip>
            </div>
          );
        }
      // Create a custom wrapper for the TableSortLabel to handle specific column cases
      if (![""].includes(title)) {
        const isSpecialColumn = ["text", "Category"].includes(title);
        const istext = ["text"].includes(title);
        const iscat = ["Category"].includes(title);
        return (
          <div
            // onMouseEnter={() => setHoveredColumn(column.key)}
            // onMouseLeave={() => setHoveredColumn(null)}
            className={classes.headerCell}
            style={{ 
              height: '97%', 
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#ffffff',
              position: 'relative' // Added for absolute positioning of sort icon
            }}
          >
            {istext ? (
              <>
                {/* Render content without wrapping in TableSortLabel */}
                {content}
                
                {/* Add a separate sort icon positioned absolutely */}
                <div 
                  onClick={() => onColumnSort({ column })}
                  style={{
                    position: 'absolute',
                    right: '25px',
                    bottom: '27px', // Align with the bottom of content
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <TableSortLabel
                    active={!!sortDirection}
                    direction={sortDirection || 'asc'}
                    // Override default styles to ensure icon shows correctly
                    style={{ padding: 0 }}
                  />
                </div>
              </>
            ) : 
            ( iscat ? (
              <>
                {/* Render content without wrapping in TableSortLabel */}
                {content}
                
                {/* Add a separate sort icon positioned absolutely */}
                <div 
                  onClick={() => onColumnSort({ column })}
                  style={{
                    position: 'absolute',
                    right: '8px',
                    bottom: '24px', // Align with the bottom of content
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <TableSortLabel
                    active={!!sortDirection}
                    direction={sortDirection || 'asc'}
                    // Override default styles to ensure icon shows correctly
                    style={{ padding: 0 }}
                  />
                </div>
              </>
            ) : (
              // For other columns, use the regular TableSortLabel wrapper
              <TableSortLabel
                active={!!sortDirection}
                direction={sortDirection || 'asc'}
                onClick={() => onColumnSort({ column })}
              >
                {content}
              </TableSortLabel>
            ))}
          </div>
        );
      }
      
      return (
        <div
          className={classes.headerCell}
          style={{ 
            height: '90%', 
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#ffffff'
            }}
          >
            {content}
          </div>
        );
      },
      cellRenderer: ({ rowData, column, rowIndex }) => {
        const row = rowOrder[header.indexOf(column.dataKey)];
        const value = rowData[row];
        const isFrozen = header.indexOf(column.dataKey) <= overallIndex;
        if (row === "keyDriverValue") {
          return value ? (
            <div className={`${classes.centerAlignTxt}`} style={{ padding: '4px'}}>
              <Tooltip arrow title="Key Driver">{keyDriver()}</Tooltip>
            </div>
          ) : null;
        }

        const charLimit = isFrozen ? 15 : 10;
        const showTooltip = ["_id", "Category"].includes(row) || (value?.toString().length > charLimit);
        const isExcludedRow = rowData.text === 'Number of Respondents (N)' || rowData.text === 'Overall % Favorable';
        const getBackgroundColor = () => {
          if (rowIndex <= 1) return 'transparent';
          
          if (!["_id", "Category", "Overall"].includes(row) && value >= 0 && !isExcludedRow) {
            const itemValue = parseInt(value);
            const range = colorRange.find(r => itemValue >= r.gt && itemValue <= r.lt);
            return range ? range.value : "#fff";
          }
        };
        
        return (
          <div
            className={`${leftAlignCells.includes(row) ? classes.leftAlignTxt : classes.centerAlignTxt} ${classes.cell}`}
            style={{
              backgroundColor: getBackgroundColor(),
              border: rowIndex > 1 && !["_id", "Category", "Overall"].includes(row) && value && !isExcludedRow ? "1px solid white" : "",
              height: '100%',
              padding: '8px 4px',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {showTooltip ? (
              <Tooltip arrow title={value || ''}>
                <span>{isFrozen ? value : (value >= 0 || value ? value : "-")}</span>
              </Tooltip>
            ) : (
              isFrozen ? value : (value >= 0 || value ? value : "-")
            )}
          </div>
        );
      },
    }));
  }, [
    header,
    overallIndex,
    columnWidths,
    classes,
    selectedType,
    updateSelectedType,
    keydriver,
    sortingData,
    handleSorting,
    leftAlignCells,
    colorRange,
    filtersApplied,
    JSON.stringify(filtersApplied),
    rowOrder,
  ]);

  // Handle column sort
  const onColumnSort = ({ column }) => {
    const columnKey = column.title;
    const currentOrder = sortingData[columnKey];
  
    // Find all sort icons and reset their rotation
    const allIcons = document.querySelectorAll('.MuiTableSortLabel-icon');
    allIcons.forEach(icon => {
      icon.style.transform = 'rotate(0deg)';
      icon.style.opacity = '0';
    });
    
    // Find the specific sort icon for this column and rotate it
    const headerElement = document.querySelector(`[data-key="${column.key}"] .MuiTableSortLabel-icon`);
    if (headerElement) {
      headerElement.style.opacity = '1';
      if (currentOrder === 'asc') {
        headerElement.style.transform = 'rotate(180deg)';
      } else {
        headerElement.style.transform = 'rotate(0deg)';
      }
    }
    
    // Call the original handler
    handleSorting(columnKey, currentOrder);
  };

  const getInitialFilteredData = ((data, type) => {
    // Filter is used for only question and category
    const typeFieldValue = ['question', 'keyDriver'].indexOf(type) !== -1 || ['question', 'keyDriver'].indexOf(selectedType) === -1 ? '_id' : 'catName';

    if (!data || !_.isArray(data) || data.length === 0) {
      return {}
    }

    return _.orderBy(data, [typeFieldValue]).reduce((acc, curr, index) => {
      if (curr?.[typeFieldValue] && Object.values(acc || {}).indexOf(curr?.[typeFieldValue]) === -1) {
        return { ...acc, [generateKey(curr?.[typeFieldValue])]: curr?.[typeFieldValue] }
      } else {
        return acc;
      }
    }, {});
  });

  const getSelectedFilterData = ((data, type) => {
    // Filter is used for only question and category
    const questionKeyDriverCondition = ['question', 'keyDriver'].indexOf(type) !== -1;
    const selectedCategoryCondition = selectedType === 'category';

    const typeFieldValue = selectedCategoryCondition ? '_id' : questionKeyDriverCondition ? '_id' : 'catName';
    const typeFieldValueAlternative = selectedCategoryCondition ? '_id' : questionKeyDriverCondition ? 'catName' : '_id';

    const currentType = selectedCategoryCondition ? 'category' : questionKeyDriverCondition ? selectedType : 'category';
    const currentTypeAlternative = selectedCategoryCondition ? 'category' : questionKeyDriverCondition ? 'category' : selectedType;

    const reduxDataCondition = Boolean(filtersApplied?.[type] && _.isArray(filtersApplied?.[type]) && filtersApplied?.[type].length > 0);

    if (!reduxDataCondition || (!data || !_.isArray(data) || data.length === 0)) {
      return [];
    }

    const filterSorted = _(data).filter(f => {
      return (filtersApplied?.[currentTypeAlternative] || []).indexOf(f?.[typeFieldValueAlternative]) !== -1 || (filtersApplied?.[currentType] || []).indexOf(f?.[typeFieldValue]) !== -1
    }).orderBy(typeFieldValue).value();

    return filterSorted.reduce((acc, curr, index) => {
      const currentKey = generateKey(curr?.[typeFieldValue]);

      if ((acc || []).indexOf(currentKey) === -1) {
        acc = [...acc, currentKey];
      }

      return acc;
    }, []);
  });

  // Filter Handling
  const applyFiltersBtn = (triggerType, data) => {
    setTriggerType(triggerType);
    const filterDataBasedOnType = getInitialFilteredData(tableData, triggerType);
    const isAllSelected = _.isEqual(_.orderBy(Object.keys(filterDataBasedOnType || {})), _.orderBy((data || [])));
    let reduxData = {};

    const questionKeyDriverCondition = ['question', 'keyDriver'].indexOf(triggerType) !== -1;
    const selectedCategoryCondition = selectedType === 'category';

    const localHeaderColumnName = selectedCategoryCondition ? '_id' : questionKeyDriverCondition ? '_id' : 'catName';

    let localDisplayData = displayData.slice(0, 2);
    if (!isAllSelected) {
      const triggerTypeData = (data || []).reduce((acc, curr, index) => {
        if (filterDataBasedOnType?.[curr]) {
          acc = [...acc, filterDataBasedOnType?.[curr]];
        }

        return acc;
      }, []);
      setFilteredValues(triggerTypeData)

      localDisplayData = [
        ...localDisplayData,
        ...(tableData.filter(dd => triggerTypeData.indexOf(dd[localHeaderColumnName]) !== -1))
      ];

      setDisplayData(localDisplayData);

      reduxData = ['question', 'keyDriver'].indexOf(selectedType) !== -1 ?
        {
          [triggerType]: triggerTypeData
        } : {
          [selectedType]: triggerTypeData
        };
    } else {
      setFilteredValues([]);
    }

    applyFilters(
      () => {
        ['question', 'keyDriver'].indexOf(triggerType) !== -1 || ['question', 'keyDriver'].indexOf(selectedType) === -1 ?
          setViewByAnchor(null) :
          setViewByCategoryAnchor(null)
      },
      'hotspost',
      reduxData
    )
  }

  return (
    <div className={classes.tableContainer} style={{ height: zoom ? window.innerHeight - 125 : 600 }}>
      {/* Component to hide BaseTable's default sort icons */}
      <HideSortIconStyle />
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            ref={tableRef}
            key={JSON.stringify(filtersApplied)}
            data={displayData}
            columns={columns()}
            width={width}
            height={height}
            fixed
            headerHeight={HEADER_HEIGHT}
            rowHeight={ROW_HEIGHT}
            overscanColumnCount={10}
            overscanRowCount={10}
            rowClassName={({ rowData, rowIndex }) => {
              if (rowIndex === 0 && rowData) {
                return classes.firstDataRow;
              }
              if (rowIndex === 1 && rowData) {
                return classes.secondDataRow;
              }
              return classes.staticRow;
            }}
            footerRenderer={loadingMore ? () => <div style={{ textAlign: 'center', padding: '10px' }}>Loading...</div> : null}
            emptyRenderer={() => <div style={{ textAlign: 'center', padding: '20px' }}>No data found</div>}
            sortState={sortState}
            onColumnSort={onColumnSort}
            classPrefix="BaseTable"  // Ensure class prefix is consistent
          />
        )}
      </AutoResizer>
{/* view by question - Filter Icon popover */}
<Popover
            id={'hotspotViewByPopover'}
            open={Boolean(viewByAnchor)}
            anchorEl={viewByAnchor}
            onClose={() => { setViewByAnchor(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MultiSelectChecboxWithSearch
              checkboxData={getInitialFilteredData(tableData, 'question')}
              defaultSelectAll={filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length > 0 ? false : true}
              handleApply={applyFiltersBtn}
              handleCancel={() => { setViewByAnchor(null) }}
              selectedCheckboxDatas={getSelectedFilterData(tableData, selectedType)}
              triggerType={selectedType}
            />
          </Popover>

          {/* view by category - Filter Icon popover */}
          <Popover
            id={'hotspotViewByCategoryPopover'}
            open={Boolean(viewByCategoryAnchor)}
            anchorEl={viewByCategoryAnchor}
            onClose={() => { setViewByCategoryAnchor(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MultiSelectChecboxWithSearch
              checkboxData={getInitialFilteredData(tableData, 'category')}
              defaultSelectAll={filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length > 0 ? false : true}
              handleApply={applyFiltersBtn}
              handleCancel={() => { setViewByCategoryAnchor(null) }}
              triggerType={'category'}
              selectedCheckboxDatas={getSelectedFilterData(tableData, 'category')}
            />
          </Popover>
        </div>
  );
};

// PropTypes and Default Props
HotspotStickyTable.propTypes = {
  classes: PropTypes.object,
  selectedType: PropTypes.string,
  updateSelectedType: PropTypes.func,
  header: PropTypes.array,
  staticRow: PropTypes.array,
  tableData: PropTypes.array,
  rowOrder: PropTypes.array,
  colorRange: PropTypes.array,
  sortingData: PropTypes.object,
  leftAlignCells: PropTypes.array,
  handleSorting: PropTypes.func,
  zoom: PropTypes.bool,
  applyFilters: PropTypes.func,
  filtersApplied: PropTypes.object,
  fetchMoreData: PropTypes.func,
};

HotspotStickyTable.defaultProps = {
  classes: {},
  selectedType: "question",
  updateSelectedType: () => {},
  header: [],
  staticRow: [],
  tableData: [],
  rowOrder: [],
  colorRange: [],
  sortingData: {},
  leftAlignCells: [],
  handleSorting: () => {},
  zoom: false,
  applyFilters: () => {},
  filtersApplied: {},
  fetchMoreData: null,
};

export default withStyles(HotspotTableStyle)(HotspotStickyTable);