/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-key */
/* eslint-disable dot-notation */
/* eslint-disable curly */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";

// Import Icons
import { SettingAccordGrey, SettingAccordWhite } from "../../../../assets/images/svgComponents/ImageComponent";

import CloseIcon from "@mui/icons-material/Close";
import Addquestion from "../../../../assets/images/svg/plus-blue.svg";

// Import Constants
import { participantConstants } from "../../../../config/constants";
import { participantConstant } from "../../../../redux/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SearchInputCompoment from "../../../../components/SearchInputCompoment/SearchInputComponent";
import DeleteDialogComponent from "../../../../components/DeleteDialogComponent/DeleteDialogComponent";
import { participantAction, updateStateReducerAction, alertAction } from "../../../../redux/actions";

function AssignedSurveyPanel(props) {
  // Assign Props
  const { classes, participant, setCalc, params } = props;


  // Accordion
  const [expanded, setExpanded] = useState("assignedsurvey-panel");
  const [searchKey, setSearch] = useState("");
  const [emptySurvey, setEmptySurvey] = useState(true);
  const [unAssignDialog, setUnAssignDialog] = useState(null);
  const [confirmationOne, setConfirmationOne] = useState(false);
  const [confirmationTwo, setConfirmationTwo] = useState(false);

  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Define Dispatch
  const dispatch = useDispatch();

  // Define Selector
  const { surveys, getOne: { survey }, clientSurveys } = useSelector((state) => state.participant);
  let slaSurveys = [];
  const userProfile = useSelector((state) => state.profile.user);
  if (userProfile.hasOwnProperty("surveyAccess")) {
    slaSurveys = userProfile?.surveyAccess?.surveys || [];
  }


  /**
     * Load Survey List
     */
  useEffect(() => {
    dispatch(participantAction.surveySearch(""));
  }, [dispatch]);

  /**
     * Check Survey is Empty or not
     */
  useEffect(() => {
    if (surveys.length > 0) {
      setEmptySurvey(false);
    }
  }, [surveys]);

  /**
     * UnAssign Survey
     * @param {*} survey
     */
  const handleDelete = (survey) => () => {
    // for update
    if (participant._id) {
      setUnAssignDialog(survey);
      setConfirmationOne(true);
    } else {
      // for add new participant
      const index = participant.survey.indexOf(survey)
      if (index >= 0) {
        participant.survey.splice(index, 1)
      }
      dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_UPDATE, {
        ...participant
      }));
    }
  };

  const onAddToReport = (status) => {
    const survey = unAssignDialog
    survey.assigned_status = false
    survey["unassignedType"] = "manual"
    survey.available_in_report = status
    // Add unassignment log to survey_logs only for existing participants
    if (participant._id) {
      participant.survey_logs = participant.survey_logs || [];
      participant.survey_logs.push({
        action: "unassigned",
        survey_id: survey.survey_id._id,
        name: survey.name,
        timestamp: new Date().toISOString(),
        survey_detail: survey
      });
    }
    setUnAssignDialog(null);
    setConfirmationOne(false);
    setConfirmationTwo(false);
    dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_UPDATE, {
      ...participant,
      survey_logs: participant.survey_logs || []
    }));
  }

  /**
     * Search Survey Event
     */
  const onInputChangeRequest = (searchField, searchKey) => {
    // Remove '/' and '\' characters from the searchKey
    const sanitizedSearchKey = searchKey.replace(/[\\/%]/g, '');
    setSearch(sanitizedSearchKey);
    
    if (sanitizedSearchKey && sanitizedSearchKey.length > 0) {
      dispatch(participantAction.surveySearch(sanitizedSearchKey.toLowerCase()));
    } else {
      dispatch(participantAction.surveySearch(""));
    }
  };
  

  /**
     * Confirmation Popup and Its Event
     */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  /**
     * Generate survey chips
     */
  const generateChips = () => {

    return participant.survey.filter(a => a.assigned_status).map(survey => {

      let _id, name;

      if (survey.survey_id) {
        if ("name" in survey) {
          _id = survey.survey_id;
          name = survey.name;
        } else {
          _id = survey.survey_id;
          name = survey.survey_id.name;
        }
        return (
          <Chip
            key={_id}
            label={name}
            classes={{
              root: classes.assignSurveyChip
            }}
            disabled={(slaSurveys.length > 0 && !slaSurveys.includes(survey.survey_id._id)) || (slaSurveys.length==0 && userProfile?.role != "superadmin") ? true : false}
            onDelete={handleDelete(survey)}
            deleteIcon={<CloseIcon className={classes.assignSurevy_chipClose} />}
          />
        );
      }
    });
  };

  /**
     * Assign Survey to Participant
     */
  const assignSurvey = (selectedSurvey) => {
    let isUpdate = true;
    let newSurvey = {
      // survey_id: selectedSurvey._id._id,
      survey_id: {
        frequency: selectedSurvey._id.frequency,
        name: selectedSurvey._id.name,
        status: selectedSurvey._id.status,
        _id: selectedSurvey._id._id,
      },
      name: selectedSurvey._id.name,
      frequency: selectedSurvey._id.frequency,
      status: selectedSurvey._id.status,
      pulse: selectedSurvey._id.frequency === "Pulse" ? true : false,
      count: 1,
      date: new Date(),
      pulse_sent: [],
      assigned_date: moment().format("YYYY-MM-DD"),
      assigned_status: true,
      available_in_report: true
    };

    //update pulse invite count
    if (selectedSurvey._id.currentPulse && newSurvey.pulse_sent.indexOf(selectedSurvey._id.currentPulse) === -1) {
      newSurvey.pulse_sent.push({ "pulse": selectedSurvey._id.currentPulse, "date": new Date() })
    }

    participant.survey.filter(a => a.assigned_status).map(data => {
      if (data.survey_id) {
        let survey;
        if ("name" in data)
          survey = data.name;
        else
          survey = data.survey_id.name;

        if (survey === selectedSurvey._id.name) {
          dispatch(alertAction.error(participantConstants.ALERT_SURVEY_EXIST));
          isUpdate = false;
        }
      } else {
        isUpdate = false;
      }
    });

    if (isUpdate) {
      // if survey already present in current date remove it
      participant.survey = participant.survey.filter(s => {
        let aSurvey_id = null
        if (s && s.survey_id) {
          aSurvey_id = s.survey_id._id ? s.survey_id._id : s.survey_id
        }
        if (s && s.assigned_date === moment().format("YYYY-MM-DD")
          && `${newSurvey.survey_id._id}` === `${aSurvey_id}`) {
          return false
        }
        return true
      })
      // Add survey logs only for existing participants
      if (participant._id) {
        participant.survey_logs = participant.survey_logs || [];
        participant.survey_logs.push({
          action: "assigned",
          survey_id: newSurvey.survey_id._id,
          name: newSurvey.name,
          timestamp: new Date().toISOString(),
          survey_detail: newSurvey
        });
      }

      /**
    * Tenure & Age Calculation
    */
      const calculateAgeTenure = () => {
        const brithDataCalc = new Date(participant.birth_date);
        if (participant.birth_date && moment(brithDataCalc).isValid()) {
          const secondaryDate = participant.term_date ? new Date(participant.term_date) : new Date();
          const birthYear = moment(brithDataCalc).format('YYYY');
          const diffDays = Math.ceil(Math.abs(secondaryDate - brithDataCalc) / (1000 * 60 * 60 * 24));
          const ageCalculation = (diffDays / 365).toFixed(4);
          participant["age_calculation"] = ageCalculation;

          //Generation Calculation
          const generationGroup = participantConstants.PARTICIPANT_GENERATION_GROUP.find((val) => val.min <= birthYear && val.max >= birthYear);
          participant["generation"] = generationGroup.value;

          //Age Grouping Calculation
          const ageGroup = participantConstants.PARTICIPANT_AGE_GROUP.find((val) => val.min <= ageCalculation && val.max > ageCalculation)
          participant["age_groupings"] = ageGroup.value;

        } else {
          participant["age_calculation"] = "";
        }

        const hireDateCalc = new Date(participant.hire_date);
        if (participant.hire_date && moment(hireDateCalc).isValid()) {
          const secondaryDate = participant.term_date ? new Date(participant.term_date) : new Date();
          const diffDays = Math.ceil(Math.abs(secondaryDate - hireDateCalc) / (1000 * 60 * 60 * 24));
          const tenureCalculation = (diffDays / 365).toFixed(4);
          participant["tenure_calculation"] = tenureCalculation
          //Tenure Grouping Calculation
          const tenureGroup = participantConstants.PARTICIPANT_TENURE_GROUP.find((val) => val.min <= tenureCalculation && val.max > tenureCalculation)
          participant["tenure_groupings"] = tenureGroup.value
        } else {
          participant["tenure_calculation"] = "";
          participant["tenure_groupings"] = "";
        }

        setCalc(true);
      }
      if (params) {
        dispatch(participantAction.ageTenureCalculation([participant.empId], calculateAgeTenure, setAnchorEl))
        participant.survey.push(newSurvey);
        dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_UPDATE, {
          ...participant,
          survey_logs: participant.survey_logs || []
        }));
        setAnchorEl(null);
      } else {
        calculateAgeTenure()
        participant.survey.push(newSurvey);
        dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_UPDATE, {
          ...participant,
          survey_logs: participant.survey_logs || []
        }));
        setAnchorEl(null);
      }

    }
  };

  /**
     * Generate Popover
     */
  const generatePopOver = () => {
    return (
      <React.Fragment>
        <SearchInputCompoment
          value={searchKey}
          className="m-15"
          placeholder='Search'
          timeOut={50}
          onInputChangeRequest={onInputChangeRequest} />
        <Paper style={{ maxHeight: 200, overflow: "auto" }}>
          <List component="nav" aria-label="secondary mailbox folders">
            {
              clientSurveys && clientSurveys.length > 0 ? clientSurveys.slice(0).reverse().map((survey) => {

                return <ListItem className={`${classes.assignSurvey_ListItem}`} onClick={() => assignSurvey({ _id: survey })} button>
                  <ListItemText primary={"name" in survey ? survey.name : survey.survey_id.name} />
                </ListItem>;
              })
                : <ListItem className={classes.assignSurvey_ListItem} button>
                  <ListItemText primary={"No results found"} />
                </ListItem>
            }
          </List>
        </Paper>
      </React.Fragment>
    );
  };


  const clearConfirmations = () => {
    setConfirmationOne(false);
    setConfirmationTwo(false);
    setUnAssignDialog(null);
  }

  return (
    <Accordion
      className="mb-2 pe-accordion"
      expanded={expanded === "assignedsurvey-panel"}
      onChange={handlePanelChange("assignedsurvey-panel")}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {expanded === "assignedsurvey-panel" ?
          <Grid className={"pr5"}>
            {SettingAccordWhite()}
          </Grid>
          :
          <Grid className={"pr5"}>
            {SettingAccordGrey()
            }
          </Grid>}
        <TypographyComponent
          className={`${"txtBold flexBasis33 mt2"} ${expanded === "assignedsurvey-panel" ? "panel-active" : ""} `}
          variant={"h5"}>
          {participantConstants.ASSIGNED_SURVEY}</TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "assignedsurvey-panel" ? "panel-active" : ""}`}
          variant={"h6"}
        >{participantConstants.ASSIGNED_SURVEY_HELPTEXT}</TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className="p-2">
          <Grid container spacing={3} className={`${classes.setting_expandcontainer} pt-2`}>
            {generateChips()}
          </Grid>
          <Grid item className="mt-3">
            {emptySurvey
              ? <TypographyComponent variant={"h6"}>{participantConstants.NO_SURVEYS}</TypographyComponent>
              : <React.Fragment>
                <Link onClick={handleClick} className={`${classes.quest_linkimg} cursor-pointer`}
                  color="primary">
                  <IconButton color="inherit" className={"p-0"} size="large">
                    <img src={Addquestion} alt={""} /></IconButton>
                  Assign a survey
                </Link>

                <Popover open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                  onClose={handleClose}
                  className="p-2"
                >{generatePopOver()}</Popover>
              </React.Fragment>
            }
          </Grid>

          {/* First Confirmation Validation */}
          <DeleteDialogComponent
            open={confirmationOne && !confirmationTwo}
            content={participantConstants.UNASSIGN_IMPORT_CONFIRMATION_ONE}
            handleDeleteAction={() => setConfirmationTwo(true)}
            handleDeleteOnCloseAction={() => setConfirmationOne(false)}
            padding="p-40"
          />

          {/* Second Confirmation Validation */}
          < DeleteDialogComponent
            open={confirmationOne && confirmationTwo}
            content={participantConstants.UNASSIGN_IMPORT_CONFIRMATION_TWO}
            handleDeleteAction={() => onAddToReport(true)}
            handleDeleteOnCloseAction={() => clearConfirmations()}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

// default props
AssignedSurveyPanel.defaultProps = {
  classes: {},
  participant: {}
};

// prop types
AssignedSurveyPanel.propTypes = {
  classes: PropTypes.object,
  participant: PropTypes.object
};

export default (AssignedSurveyPanel);
