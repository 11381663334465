import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import _ from "lodash";

// Import Styles and Image
import ReportsStyles from "./ReportsStyles";

// Import Constants
import { surveyTypes } from "../../config/constants"

// Import Support Containers
import Filter from "../Filter/Filter"
import ME_TopWidgets from './Dashboard/ME_TopWidgets'
import TopWidgets from './Dashboard/TopWidgets'
import MEFavorableWidget from './Dashboard/Widgets/MEFavourableWidget'
import BottomWidgets from './Dashboard/BottomWidgets'
import ME_BottomWidgets from './Dashboard/ME_BottomWidgets'
import DlaRole from "./DlaRole"

//Import Actions
import { dashboardAction, adminsAction } from '../../redux/actions'

function Dashboard(props) {

    // Get props
    const { classes, match, location } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    // Redux Selectors
    const preference = useSelector(state => state?.admin?.preference);
    const { queryDLA } = useSelector(state => state.profile.user)
    const { dashboard: { dashboardData } } = useSelector(state => state)
    const { filterData } = useSelector(state => state.filter)
    const { overAllDashboard } = useSelector((state) => state.dashboard.initialReload);

    const MEtype = filterData.surveys.length > 0 && filterData.surveys.filter((val) => val.type === surveyTypes.MULTI_RATER_FEEDBACK)

    // Whenever there is a update made in this callDashboardApi function update vice versa in Reports.jsx callDashboardApi function as well
    const callDashboardApi = () => {
        const dashboardSurveys = filterData?.surveys || [];
        const surveyTypesArr = dashboardSurveys.map(survey => survey.type);
        const surveyFrequencyArr = dashboardSurveys.map(survey => survey.frequency);
        let requestCount = 6;
        
        // Participation widget & Overall Favorability widget
        dispatch(dashboardAction.getDashboardData(() => { }, 'count'));
        dispatch(dashboardAction.getDashboardData(() => { }, 'rollingParticipant'));
        dispatch(dashboardAction.getParticipantInvite(false));

        // Favorability by Category widget
        dispatch(dashboardAction.getDashboardData(() => { }, 'favourableCategory'));

        // Overall eNPS widget
        dispatch(dashboardAction.getDashboardData(() => { }, 'overalleNPS'));

        // Highest & Low Highest Rated Items widget
        dispatch(dashboardAction.getDashboardData(() => { }, 'questions'));


        if ((dashboardSurveys.length === 1 && dashboardSurveys?.[0]?.type !== surveyTypes.ENGAGEMENT) || (dashboardSurveys.length === 1 && dashboardSurveys?.[0]?.type === surveyTypes.ENGAGEMENT && !_.isEmpty(queryDLA)) ||
        (dashboardSurveys.length > 1)) {
            requestCount += 1;
            // Suggested Area of Actions widget
            dispatch(dashboardAction.getDashboardActionData(() => { }, false));
        } else {
            requestCount += 1;
            dispatch(dashboardAction.getDashboardActionData(() => { }, false, false))
        }

        if (dashboardSurveys.length === 1) {
            const currentSurveyType = dashboardSurveys[0]?.type;

            if (currentSurveyType === surveyTypes.ENGAGEMENT) {
                requestCount += 1;
                // Engagement Benchmark widget
                dispatch(dashboardAction.getDashboardData(() => { }, 'engagementBenchmark'));
                if (!queryDLA || queryDLA === "") {
                    requestCount += 1;
                    // Top Keydrivers Engagement
                    dispatch(dashboardAction.getKeyDriverData(() => { }, false));
                }
            } else if (currentSurveyType === surveyTypes.EXIT) {
                // Turnover widget
                if (!surveyFrequencyArr.includes("Pulse")) {
                    requestCount += 1;
                    dispatch(dashboardAction.getDashboardData(() => { }, 'turnOver'));
                }
                requestCount += 1;
                // Likely to Return widget
                dispatch(dashboardAction.getDashboardData(() => { }, 'likelyToReturn'));
            } else if (currentSurveyType === surveyTypes.STAY || currentSurveyType === surveyTypes.NEW_HIRE) {
                // Turnover widget
                if (!surveyFrequencyArr.includes("Pulse")) {
                    requestCount += 1;
                    dispatch(dashboardAction.getDashboardData(() => { }, 'turnOver'));
                }
                requestCount += 1;
                // Likely to Stay widget
                dispatch(dashboardAction.getDashboardData(() => { }, 'likelyToStay'));
            }
        } else {
            if (
                surveyTypesArr.length === 1 && surveyTypesArr.indexOf(surveyTypes.STAY) !== -1 ||
                surveyTypesArr.length === 2 && _.isEqual([surveyTypes.ENGAGEMENT, surveyTypes.NEW_HIRE], surveyTypesArr.sort())
            ) {
                requestCount += 1;
                // Likely to Stay widget
                dispatch(dashboardAction.getDashboardData(() => { }, 'likelyToStay'));
            }

            if (
                surveyTypesArr.indexOf(surveyTypes.CUSTOM_SURVEY) === -1 &&
                (
                    surveyTypesArr.indexOf(surveyTypes.STAY) !== -1 ||
                    (
                        surveyTypesArr.length === 2 &&
                        (
                            _.isEqual([surveyTypes.ENGAGEMENT, surveyTypes.NEW_HIRE], surveyTypesArr.sort()) ||
                            _.isEqual([surveyTypes.EXIT, surveyTypes.NEW_HIRE], surveyTypesArr.sort()) 
                        )
                    )
                )
            ) {
                // Turnover widget
                if (!surveyFrequencyArr.includes("Pulse")) {
                    requestCount += 1;
                    dispatch(dashboardAction.getDashboardData(() => { }, 'turnOver'));
                }
            }
        }

        dispatch(dashboardAction.addDashboardRequestCount(requestCount));
    }

    // Load Dashboard Data
    useEffect(() => {
        if (match.path === "/reports" && !overAllDashboard) {
            dispatch(dashboardAction.resetDashboardLoader());
            callDashboardApi();
        }
    }, []);

    useEffect(() => {
        if (
            dashboardData.dashBoardLoaderRequestCompletedCount !== 0 &&
            dashboardData.dashBoardLoaderRequestCount !== 0 &&
            dashboardData.dashBoardLoaderRequestCompletedCount >= dashboardData.dashBoardLoaderRequestCount
        ) {
            dispatch(dashboardAction.updateDashboardLoader());
        }

    }, [dashboardData.dashBoardLoaderRequestCompletedCount])

    // Object to use current admin Prefence and update the dashboard hide widget in preference
    const adminPreference = {
        data: preference,
        updatePreference: (preference) => {
            dispatch(adminsAction.updateDashWidgetPreference(preference));
        }
    }

    return (
        <Grid container>
            <Grid container spacing={3} alignItems="stretch" className={`mb8 ${classes.execDashContainer}`}>
                {queryDLA && queryDLA !== " " &&
                    <DlaRole />
                }
                {MEtype.length > 0 && dashboardData.totalReviewItems !== 0 ? <ME_TopWidgets /> : <TopWidgets adminPreference={adminPreference} />}
            </Grid>

            <Grid container alignItems="stretch" spacing={3} className="mb-2">
                <Grid item style={{ flexGrow: "4" }}>
                    <Grid container alignItems="stretch" style={{ width: "100%", height: "100%" }}>
                        {MEtype.length > 0 && dashboardData.totalReviewItems !== 0 ? <ME_BottomWidgets /> : <BottomWidgets adminPreference={adminPreference} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
// default props
Dashboard.defaultProps = {
    classes: {},
    dashboardData: {
        comments: 0,
        overallFavorable: "0.00",
        questions: [],
        saoa: [],
        totalresponse: 0,
        totalInvite: 0
    }
};

// prop types
Dashboard.propTypes = {
    classes: PropTypes.object,
    dashboardData: PropTypes.object,
};
export default withStyles(ReportsStyles)(Dashboard);