/* eslint-disable react/jsx-key */
/* eslint-disable quote-props */
/* eslint-disable prefer-const */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';

// Import Styles and Image 
import { DialogueClose, DrawerClose } from "../../assets/images/svgComponents/ImageComponent";

import ActionsStyles from "./ActionsStyles";

// Import History
import history from "../../config/history";

// Import Component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DatePickerComponent from "../../components/DatePickerComponent/CustomDatePicker";
import AutoCompleteComponent from "../../components/AutoCompleteComponent/AutoCompleteComponent";
import ToolTipComponent from '../../components/ToolTipComponent/ToolTipComponent';

// Import Constants
import { ActionConstants, errorsConstants, surveyTypes } from "../../config/constants";
import { actionsConstant } from "../../redux/constants";

// Import Actions
import { actionsAction, updateStateReducerAction, alertAction } from "../../redux/actions";

// Import Helpers
import { getReducerUpdatedData, generateOverViewText, getStartDate, getEndDate, updateLinksInHTML, dateFormatter } from "../../helpers";

function NewActionPlan(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // Get Preferences Data
  let { filterData } = useSelector(state => state.filter);
  let preference = { ...filterData }

  const { addedchoice } = useSelector(state => state.actionplan);
  let newItems = (addedchoice.actionItems && addedchoice.actionItems.Default) ? [...addedchoice.actionItems.Default] : []

  /**Question ID*/
  preference['questionId'] = addedchoice.baseQusId;
  preference['newactions'] = newItems;
  preference['selectedDemographics'] = filterData.demographics;

  let surveysDet = preference.surveys;
  let dateDet = preference.date

  /**Dispatchin Action Plan Data */
  useEffect(() => {
    dispatch(actionsAction.actionplan(preference));
  }, []);

  // get props
  const { classes, handleNewPlanClose } = props;

  // Get Particular Plan from store
  const { getOne } = useSelector(state => state.actionplan);
  const { metadata } = useSelector(state => state.metadata)
  let actionplan = { ...getOne };
  actionplan['score'] = addedchoice.percentfavorable;
  actionplan['selectedDemographics'] = {};

  Object.keys(filterData.demographics).map((key) => {
    actionplan['selectedDemographics'][key] = {
      rgName: key,
      rgItemArray: filterData.demographics[key]
    }
  })

  /**To get Filter Details */
  const filterDetails = generateOverViewText('full', surveysDet, dateDet);
  actionplan['selectedSurveyTemplate'] = filterDetails.split("|")[0]

  const filterStartDate = getStartDate(dateDet)
  const filterEndDate = getEndDate(dateDet)
  actionplan['demographicFilters'] = ""

  actionplan['selectedSurveyDates'] = [`${filterStartDate} - ${filterEndDate}`]

  // convert - to / in selectedsurveyDate endDate
  let upDate = actionplan.selectedSurveyDates[0] || ""

  let filterFields = `Filters applied: ${actionplan.selectedSurveyTemplate} | ${upDate}`
  if (actionplan.selectedDemographics) {
    Object.keys(actionplan.selectedDemographics).map((key) => {
      let metaVal = metadata.filter(obj => obj.key === key)
      actionplan['demographicFilters'] += `${metaVal[0] ? metaVal[0].value : key}: ${actionplan.selectedDemographics[key].rgItemArray}; `
    })
    filterFields += ` | ${actionplan['demographicFilters']}`
  }

  /**Redirecting to Edit Action Page*/
  if (actionplan.editable) {
    history.push({
      pathname: `/actions/editplan/${actionplan._id}`
    });
  }

    // convert date
    const convertDate = (dateStr) => {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
  
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      const finaldate = `${month}/${dt}/${year}`;
      return finaldate === "01/01/1970" ? "" : finaldate;
    };

  /**
    * Handle Form Submit
    */
  function handleSubmit(e) {
    let actionItemCheck = (actionplan.actionItems || []).filter(data => data !== null && data !== "" && data.trim() !== "")
    if (actionItemCheck.length === 0) {
      dispatch(alertAction.error("Actions cannot be empty"));
    }
    else {
      e.preventDefault();
      const action_startDate = convertDate(actionplan.startDatePlan)
      const action_endDate = convertDate(actionplan.dueDatePlan)
      actionplan["startDatePlan"] = dateFormatter(action_startDate)
      actionplan["dueDatePlan"] = dateFormatter(action_endDate)
      dispatch(actionsAction.create(actionplan));
    }
  }

  /**
  * Handle Add aciton Items
  */
  function handleAddactionItems(e) {
    let { actionItems } = actionplan;
    actionItems=actionItems || [];
    actionItems = [...actionItems, ""];
    e.preventDefault();
    handleChange({ name: "actionItems", value: actionItems });
  }

  /** Date Validation */
  useEffect(() => {
    ValidatorForm.addValidationRule("isEnddategreater", (value) => {
      if (actionplan.dueDatePlan) {
        if (new Date(actionplan.startDatePlan) <= new Date(actionplan.dueDatePlan)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
  }, [actionplan.startDatePlan, actionplan.dueDatePlan]);

  /**
   * Handle form change
   */
  const handleChange = (event) => {
    const reducerData = getReducerUpdatedData(event, actionplan);
    dispatch(updateStateReducerAction.update(actionsConstant.ACTION_PLAN_GET_ONE_UPDATE, reducerData));
  };

  return (
    <React.Fragment>
      <ValidatorForm
        id="actionNewForm"
        name="actionNewForm"
        useref="actionNewForm"
        autoComplete="off"
        onSubmit={handleSubmit}
        onError={(errors) => console.log(errors)}
      >
        <Grid className="centercontainer">
          <Grid container justifyContent="flex-end" className="mt-1">
            <Button
              variant="text"
              size={"small"}
              onClick={handleNewPlanClose}
              className="mr-5">
              {DialogueClose()}
              <TypographyComponent variant="h5" className="pl5">Close</TypographyComponent>
            </Button>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <TypographyComponent variant="h4" className="mb5 fontWeightBold">
                {ActionConstants.ADD_PLAN}
              </TypographyComponent>
              <TypographyComponent variant="h6" className="font-italic">
                {ActionConstants.ADD_PLAN_TEXT}
              </TypographyComponent>
              <hr className="horizontal-line" />
            </Grid>

            <Grid item sm={12} marginTop={'-15px'} >
              <Grid display={"flex"}>
                  <TypographyComponent sm={4} variant="h6" className="fontWeightBold">
                    {ActionConstants.SURVEY_NAME}: 
                  </TypographyComponent>
                  <Grid container sm={8} marginLeft={1} className={"divWidth"}>
                    <TypographyComponent
                      variant="h6"
                      id={"actionplan_surveyname"}
                      className="height36"
                      name={"surveyNames"}
                      disabled={true}
                      handleChange={(e) => handleChange(e)}
                    >{actionplan.surveyNames}</TypographyComponent>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} marginTop={'-15px'}>
            <Grid display={"flex"} >
                  <TypographyComponent sm={4}  variant="h6" className="fontWeightBold">
                    {ActionConstants.ITEMOF_FOCUS}: 
                  </TypographyComponent>
                  <Grid container sm={8} marginLeft={1} className={"divWidth"}>
                    <TypographyComponent
                     variant="h6"
                      id={"actionplan_question"}
                      className="height36"
                      name={"question"}
                      disabled={true}
                    >{actionplan?.question}</TypographyComponent>
                  </Grid>
                  </Grid>
            </Grid>
            <Grid item className={`bgwhite borderRadius30 dflexOnly ${classes.ItemScore}`} >
                  <TypographyComponent variant="h6">
                    {ActionConstants.ITEM_SCORE}: 
                  </TypographyComponent>
                    <TypographyComponent
                      variant="h6"
                      id={"actionplan_score"}
                      className="height36 pl5"
                      name={"score"}
                      handleChange={(e) => handleChange(e)}
                      validators={["required"]}
                      disabled={true}
                    >{actionplan?.score}%
                    </TypographyComponent>
                </Grid>
            <Grid item sm={12}>
              <Grid container spacing={8}>
                <Grid item sm={6}>
                  <TypographyComponent variant="h6" className={"fontWeightBold"}>
                    {ActionConstants.PLAN_NAME}
                  </TypographyComponent>
                  <Grid container sm={12} className={"divWidth"}>
                    <TextFieldComponent
                      id={"actionplan_name"}
                      className="height36"
                      name={"name"}
                      value={actionplan?.name}
                      margin={"dense"}
                      handleChange={(e) => handleChange(e)}
                      validators={["required"]}
                    ></TextFieldComponent>
                  </Grid>

                </Grid>            
                <Grid item sm={6}>
                  <TypographyComponent variant="h6" className={"fontWeightBold"}>
                    {ActionConstants.PLAN_CREATOR}
                  </TypographyComponent>
                  <Grid container sm={12} className={"divWidth"}>
                    <TextFieldComponent
                      id={"actionplan_creator"}
                      className="height36"
                      name="creator"
                      value={actionplan?.creator}
                      margin={"dense"}
                      handleChange={(e) => handleChange(e)}
                    ></TextFieldComponent>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>   
            <Grid item sm={12}>
              <Grid container spacing={8}>
                <Grid item sm={12}>
                  <Grid item sm={12} className={classes.ActionItem_Box}>
                  <TypographyComponent variant="h6" className="pb5 fontWeightBold m-1" >
                    {ActionConstants.ACTION_ITEM}
                  </TypographyComponent>
                                                    
                    {actionplan.actionItems && (actionplan.actionItems || []).map((item, index) => {
                      item = updateLinksInHTML(item)
                      return (
                        <Grid container alignItems="center" className="pb-1">
                          <Grid item sm={12} className={`${classes.Addaction_container} ${classes.Addaction_container_customize}`}>
                            <TextField
                              id={"actionItems"}
                              name={"actionItems"}
                              className={`${classes.ActionMultiLineText} ${"bordernone fieldset bgWhite width100 hauto lineHeight18 removeTopMargin"}`}
                              value={item}
                              type={"text"}
                              multiline
                              onChange={(e) => {
                                const actionItems = [...actionplan.actionItems];
                                actionItems[index] = e.target.value;
                                handleChange({ name: "actionItems", value: actionItems });
                              }}
                              variant="outlined"
                            />
                            <Grid item sm={1} className="txtright bgWhite " 
                              onClick={(e) => {
                                const actionItems = [...actionplan.actionItems];
                                actionItems.splice(index, 1);
                                handleChange({ name: "actionItems", value: actionItems });
                              }}
                            >
                              <IconButton size="large" className="mr5 mt5 "  >
                                {DrawerClose()}
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })
                    }
                    <Grid container className={classes.ActionItem_container} marginBottom={"10px"}>
                    <ButtonComponent
                     title={ActionConstants.ADD_ACTION}
                      color={'primary'}
                      handleClick={(e) => handleAddactionItems(e)}
                    >
                    </ButtonComponent>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={8}>
                <Grid item sm={4}>
                  <TypographyComponent variant="h6">
                    {ActionConstants.START_DATE}
                  </TypographyComponent>
                  <DatePickerComponent
                    name="startDatePlan"
                    value={actionplan?.startDatePlan}
                    className={`${"height36"} ${classes.textField}`}
                    handleChange={(e) => handleChange(e)}
                    inputVariant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth={true}
                    disabled={false}
                    validators={["required", "isEnddategreater"]}
                    errorMessages={[errorsConstants.required, errorsConstants.invalidDateRange]}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TypographyComponent variant="h6">
                    {ActionConstants.DUE_DATE}
                  </TypographyComponent>
                  <DatePickerComponent
                    name="dueDatePlan"
                    value={actionplan?.dueDatePlan}
                    className={`${"height36"} ${classes.textField}`}
                    handleChange={(e) => handleChange(e)}
                    inputVariant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth={true}
                    disabled={false}
                    validators={["required", "isEnddategreater"]}
                    errorMessages={[errorsConstants.required, errorsConstants.invalidDateRange]}
                  />
                </Grid>
                <Grid item sm={4}>
                  <TypographyComponent variant="h6">
                    {ActionConstants.PLAN_STATUS}
                  </TypographyComponent>
                  <AutoCompleteComponent
                    name={"status"}
                    className={`${"height36"}`}
                    suggestions={ActionConstants.statusDetails}
                    handleAutoCompolete={(event, name) => handleChange({ name, value: event.name })}
                    value={{ name: actionplan.status }}
                    validators={["required"]}
                    showErrorMessages={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container spacing={8}>
                <Grid item sm={12}>
                  <TypographyComponent variant="h6" className="pb5">
                    {ActionConstants.NOTES}
                  </TypographyComponent>
                  <TextareaAutosize
                    className={`${"width100 borderRadius6 pe_textarea"} ${classes.ActionPlanNotes}`}
                    name={"notes"}
                    placeholder={"Notes"}
                    value={actionplan?.notes}
                    onChange={(e) => handleChange({ name: "notes", value: e.target.value })}
                    minRows={3}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container className="spacebetween">
                {/* <div className="actionblue">{filterFields}</div> */}
                <ToolTipComponent title={actionplan.type && actionplan.type !== surveyTypes.MULTI_RATER_FEEDBACK ? filterFields : ""} >
                  <Grid item sm={10} className={classes.FilterField}><TypographyComponent className='cursor-pointer' color="primary" variant="h6">{actionplan.type && actionplan.type !== surveyTypes.MULTI_RATER_FEEDBACK ? 'type' in filterData.date && filterFields : ""}</TypographyComponent></Grid>
                </ToolTipComponent>
                <Grid sm={2} className="txtright">
                  <ButtonComponent
                    title={"Save"}
                    color={"primary"}
                    size={"small"}
                    type="submit"
                    form="actionNewForm"
                    pageClassName="mr-1"
                  ></ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </React.Fragment>
  );
}
// default props
NewActionPlan.defaultProps = {
  classes: {}
};

// prop types
NewActionPlan.propTypes = {
  classes: PropTypes.object
};
export default withStyles(ActionsStyles)(NewActionPlan);
