/* eslint-disable prefer-const */
// import constants
import { alertConstant, uiConstant } from "../constants";
import { ReportsConstants, surveyTypes } from "../../config/constants";
import { baseAction } from "./baseAction";
import { dashboardConstant } from "../constants/dashboardConstant";
import { adminsConstant } from "../constants";
import { dashboardService } from "../services/dashboardService";
import { saveAs } from 'file-saver';

const { request, success, failure } = baseAction;

export const dashboardAction = {
  EIOverall,
  EIWithRgi,
  getDashboardData,
  getDashboardActionData,
  getParticipantInvite,
  getHighLevelSummaryData,
  getHistoricalData,
  gethighCommentQuestions,
  getTrendsOverTimeData,
  getTrendsOverTimeDataIn,
  getYesNoData,
  getDropdownData,
  getHotspotData,
  updateHotspotData,
  getCommentAnalysis,
  updateNLPSelected,
  updateChartData,
  getGridChartData,
  updateChartVisiblity,
  downloadDashboardReport,
  eiKeysArrUpdate,
  gridKeysArrUpdate,
  updateTrendsOverTimePreference,
  getCompetencySummary,
  resetChartData,
  getKeyDriverData,
  updateCommentChartStatus,
  updateSummaryWidgetColumnsDropdown,
  updateSelectedSummaryWidgetColumnsDropdown, 
  downloadDashboardReportPPT,
  applyFilters,
  resetDataFiltersAppliedFlag,
  postKeyDriverData,
  resetDashboardLoader,
  addDashboardRequestCount,
  updateDashboardLoader,
  incrementDashboardRequestCount,
};
/**
 * Get Dashboard Widget Data
 * @param {*} successCallback 
 */
function getDashboardData(successCallback = () => { }, type = "count", diffData = false) {
  return (dispatch, getState) => {
    const { engagementComparison } = getState().client?.getOne;

    dispatch(request(dashboardConstant.GET_DASHBOARD_OVERALL_REQUEST));
    dashboardService.getDashboardData(type, diffData).then(resp => {
      resp["type"] = type
      if (type === "engagementBenchmark" && resp?.data?.engagementBenchMark?.historicalData) {
        if (engagementComparison) {
          dispatch(dashboardAction.incrementDashboardRequestCount());
          dispatch(dashboardAction.getDashboardData(() => { }, 'historical_engagementBenchmark'))
        } else {
          dispatch(success(dashboardConstant.ENGAGEMENT_HISTORICAL_UPDATE));
        }
      }
      dispatch(success(dashboardConstant.GET_DASHBOARD_OVERALL_SUCCESS, resp));
      successCallback()
    }, error => {
      dispatch(failure(dashboardConstant.GET_DASHBOARD_OVERALL_FAILURE, error));
      successCallback()
    });
  };
}

/**
 * Get Dashboard Action Widget Data
 * @param {*} successCallback 
 */
function getDashboardActionData(successCallback = () => { }, loader = true, end = true) {
  return dispatch => {
    if (!end) {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(dashboardConstant.GET_DASHBOARD_FAILURE, 'error'));
      successCallback();
      return;
    }
    if (loader) {
      dispatch(request(uiConstant.LOADING_TRUE));
    }
    dispatch(request(dashboardConstant.GET_DASHBOARD_REQUEST));
    dashboardService.getDashboardActionData().then(resp => {
      dispatch(success(dashboardConstant.GET_DASHBOARD_SUCCESS, resp));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback()
    }, error => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(dashboardConstant.GET_DASHBOARD_FAILURE, error));
      successCallback()
    });
  };
}

/**
 * Get Participant Invite widget
 * @param {*} filterData 
 */
function getParticipantInvite(loader = true) {
  return (dispatch, getState) => {
    if (loader) {
      dispatch(request(uiConstant.LOADING_TRUE));
    }
    dispatch(request(dashboardConstant.GET_PARTICIPANT_INVITE_REQUEST));
    dashboardService.getParticipantInvite().then(resp => {
      dispatch(success(dashboardConstant.GET_PARTICIPANT_INVITE_SUCCESS, resp));
      dispatch(request(uiConstant.LOADING_FALSE));
    }, error => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(dashboardConstant.GET_PARTICIPANT_INVITE_FAILURE), error);
    });
  };
}
/**
 * engagement index overaal calculation data
 */
function EIOverall(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_EI_OVERALL_REQUEST));
    dashboardService.EIOverall().then(
      (data) => {
        dispatch(success(dashboardConstant.GET_EI_OVERALL_SUCCESS, data));
        successCallback()
      },
      (error) => {
        dispatch(failure(dashboardConstant.GET_EI_OVERALL_FAILURE), error);
        successCallback()
      });
  };
}

/**
 * engagement index with Reportt group items calculation data
 */
function EIWithRgi(filterData) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_EI_RGI_REQUEST));
    dashboardService.EIWithRgi(filterData).then(
      (data) => {
        dispatch(success(dashboardConstant.GET_EI_RGI_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(dashboardConstant.GET_EI_RGI_FAILURE), error);
      });
  };
};

/**
 * High Level Summary over all data
 */
function getHighLevelSummaryData(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_HLS_REQUEST));
    dashboardService.getHighLevelSummaryData().then(resp => {
      dispatch(success(dashboardConstant.GET_HLS_SUCCESS, resp));
      successCallback()
    }, error => {
      dispatch(failure(dashboardConstant.GET_HLS_FAILURE), error);
      successCallback()
    });
  };
}

/**
 * historical data
 */
function getHistoricalData(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_HISTORICAL_REQUEST));
    dashboardService.getHistoricalData().then(resp => {
      dispatch(success(dashboardConstant.GET_HISTORICAL_SUCCESS, resp));
      successCallback()
    }, error => {

    });
  };
}

/**
 * Comment Analysis Questions
 */
function gethighCommentQuestions(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_COMMENT_QUESTION_REQUEST));
    dashboardService.gethighCommentQuestions().then(resp => {
      dispatch(success(dashboardConstant.GET_COMMENT_QUESTION_SUCCESS, resp));
      successCallback(resp.data)
    }, error => {
      dispatch(failure(dashboardConstant.GET_COMMENT_QUESTION_FAILURE), error);
      successCallback()
    });
  };
}

/**
 * Comment Analysis
 */
function getCommentAnalysis(quesId = {}, successCallback = () => { }) {
  return (dispatch, getState) => {
    const { dashboard: { commentAnalysis: { loading } } } = getState()
    if (!loading) {
      dispatch(request(dashboardConstant.GET_COMMENT_ANALYSIS_REQUEST));
      dashboardService.getCommentAnalysis(quesId).then((data) => {
        dispatch(success(dashboardConstant.GET_COMMENT_ANALYSIS_SUCCESS, data));
        successCallback()
      }, (error) => {
        // dispatch(failure(dashboardConstant.GET_COMMENT_ANALYSIS_FAILURE), error);
      });
    } else {
      console.log("ca: repeated avoid")
    }

  };
}

/**
 * trends over time action
 * @param {*} filterData 
 */
function getTrendsOverTimeData(successCallback = () => { }) {
  return dispatch => {
    //dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(dashboardConstant.GET_TOT_REQUEST));
    dashboardService.getTrendsOverTimeData().then(resp => {
      dispatch(success(dashboardConstant.GET_TOT_SUCCESS, resp));
      successCallback()
      //dispatch(request(uiConstant.LOADING_FALSE));
    }, error => {
      //dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(dashboardConstant.GET_TOT_FAILURE), error);
      successCallback()
    });
  }
}

/**
 * update trends overs custom preference    
 * @param {*} filterData 
 */
function updateTrendsOverTimePreference(surveys, successCallback = () => { }) {
  return dispatch => {
    dispatch(success(dashboardConstant.UPDATE_TOT_PREFERENCE, surveys));
  }
}

/**
 * trends over time action individual     
 * @param {*} filterData 
 */
function getTrendsOverTimeDataIn(surveys, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_TOT_REQUEST));
    dashboardService.getTrendsOverTimeData(surveys).then(resp => {
      dispatch(success(dashboardConstant.GET_TOT_SUCCESS, resp));
      successCallback()
    }, error => {
      dispatch(failure(dashboardConstant.GET_TOT_FAILURE), error);
    });
  }
}

/**
 * Yes No Chart Action
 * @param {*} filterData 
 */
function getYesNoData(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_YESNO_REQUEST));
    dashboardService.getYesNoData().then(resp => {
      dispatch(success(dashboardConstant.GET_YESNO_SUCCESS, resp));
      successCallback()
    }, error => {
      dispatch(failure(dashboardConstant.GET_YESNO_FAILURE), error);
      successCallback()
    });
  }
}

/**
 * keydriver Chart Action
 * @param {*} filterData 
 */
function postKeyDriverData(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_POSTKEY_REQUEST));
    dashboardService.postKeyDriverData().then(resp => {
      dispatch(success(dashboardConstant.GET_POSTKEY_SUCCESS, resp));
      successCallback()
    }, error => {
      dispatch(failure(dashboardConstant.GET_POSTKEY_FAILURE), error);
      successCallback()
    });
  }
}

/**
 * Dropdown Chart Action
 * @param {*} filterData 
 */
function getDropdownData(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_DROPDOWN_REQUEST));
    dashboardService.getDropdownData().then(resp => {
      dispatch(success(dashboardConstant.GET_DROPDOWN_SUCCESS, resp));
      successCallback()
    }, error => {
      dispatch(failure(dashboardConstant.GET_DROPDOWN_FAILURE), error);
      successCallback()
    });
  }
}

/**
 * Hotspot Chart Action
 */
function getHotspotData(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_HOTSPOT_REQUEST));
    dashboardService.getHotspotData().then((data) => {
      dispatch(success(dashboardConstant.GET_HOTSPOT_SUCCESS, data));
      successCallback()
    }, (error) => {
      dispatch(failure(dashboardConstant.GET_HOTSPOT_FAILURE), error);
      successCallback()
    });
  }
}

/**
 *update hotspot reducer Data
 */
function updateHotspotData(updatedData) {
  return {
    type: dashboardConstant.UPDATE_HOTSPOT_DATA,
    data: updatedData
  };
}

/**
 *update nlp selected reducer Data
 */
function updateNLPSelected(updatedData) {
  return {
    type: dashboardConstant.UPDATE_NLP_SELECTED_DATA,
    data: updatedData
  };
}

/**
 *Reset All chart Data
 */
function updateChartData() {
  return {
    type: dashboardConstant.UPDATE_CHART_DATAS
  };
}

/**
 *update summary widget columns drop down
 */
 function updateSummaryWidgetColumnsDropdown(columns) {
  return {
    type: dashboardConstant.UPDATE_SUMMARY_WIDGET_COLUMNS,
    data: columns
  };
}

// update preference for comment analysis about nlp selected
function updateSelectedSummaryWidgetColumnsDropdown(params) {
  return dispatch => {
    dispatch(success(dashboardConstant.UPDATE_SELECTED_SUMMARY_WIDGET_COLUMNS_IN_DASHBOARD, params));
  }
}

/**
 *update ei Keys Array reducer Data
 */
function eiKeysArrUpdate(updatedData) {
  return {
    type: dashboardConstant.UPDATE_EI_KEYS_DATA,
    data: updatedData
  };
}

/**
 *update gridKeysArrUpdate Keys Array reducer Data
 */
function gridKeysArrUpdate(updatedData) {
  return {
    type: dashboardConstant.UPDATE_GRID_KEYS_DATA,
    data: updatedData
  };
}

/**
 * Grid Chart Action
 * @param {*} filterData 
 */
function getGridChartData(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_GRID_REQUEST));
    dashboardService.getGridChartData().then(resp => {
      dispatch(success(dashboardConstant.GET_GRID_SUCCESS, resp));
      successCallback()
    }, error => {
      dispatch(failure(dashboardConstant.GET_GRID_FAILURE, error));
    });
  }
}
/**
 * Update Chart Visiblity
 * @param {*} charts 
 */
function updateChartVisiblity(charts) {
  return dispatch => {
    dispatch(request(dashboardConstant.CHART_VISIBILITY_REQUEST))
    dashboardService.updateChartVisiblity(charts).then(resp => {
      dispatch(success(dashboardConstant.CHART_VISIBILITY_SUCCESS, resp))
    }, error => {
      dispatch(failure(dashboardConstant.CHART_VISIBILITY_FAILURE, error))
    })
  }
}

/**
 * download dashboard report
 */
function downloadDashboardReport(includeDashboard, reportType = "Dashboard Report", dashboardDownload, isLoading,filters) {
  return (dispatch, getState) => {
    let dashboardData = getState().dashboard;
    const { admin: { preference: { reportVisibility } }, filter: {
      filterData: { surveys }
    } } = getState();
    const hasMESurvey = surveys.filter(s => s.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0
    const chartsList = Object.keys(hasMESurvey ? ReportsConstants.CHARTS_ME : ReportsConstants.CHARTS)

    let visibilityKeys = Object.keys(reportVisibility || {}).filter(k => chartsList.indexOf(k) > -1)
    let iscommentDashboard = false
    if (reportType === "Dashboard Report Comment") {
      iscommentDashboard = true
      visibilityKeys = ["1"]
    }

    const { highlevelSummary: { loading: hlsLoader },
      commentAnalysis: { loading: commentLoader },
      yesNo: { loading: yennoLoader },
      gridChart: { loading: gridLoader },
      hotspot: { loading: hotspotLoader },
      engagementIndex: { loading: engagementIndexLoader },
      trendsOverTime: { loading: TOTLoader },
      competency: { loading: CompetencyLoader } } = dashboardData
    const loaders = { hlsLoader, commentLoader, yennoLoader, gridLoader, hotspotLoader, engagementIndexLoader, TOTLoader, CompetencyLoader }
    let downloadReport = true;
    let IsDashboardDownload = false;
    if (!dashboardDownload) {
      Object.keys(loaders).map((key, index) => {
        if (loaders[key] === true && visibilityKeys.indexOf(`${index}`) !== -1) {
          downloadReport = false
        }
      })
    } else if (isLoading) {
      dispatch(failure(alertConstant.ALERT_ERROR, "Dashboard currently loading. Please try again once your dashboard updates."));
      return
    } else {
      IsDashboardDownload = true
    }
    if (downloadReport) {
      const data = {
        pdfType: "Dashboard Report",
        includeDashboard,
        dashboardData,
        iscommentDashboard,
        IsDashboardDownload,
        filters
      }
      // dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(dashboardConstant.DOWNLOAD_DASHBOARD_REQUEST))
      dashboardService.downloadDashboardReport(data).then((data) => {
        if (data && data.data && (data.data.error || data.status === "failed")) {
          dispatch(failure(alertConstant.ALERT_ERROR, data.data.error));
          dispatch(request(dashboardConstant.DOWNLOAD_DASHBOARD_FAILURE))
          dispatch(request(uiConstant.LOADING_FALSE));
        } else {
          const blob = new Blob([data.data], { type: 'application/pdf' });
          saveAs(blob, data.filename);
          dispatch(success(alertConstant.ALERT_SUCCESS, "Successfully downloaded"));
          dispatch(request(uiConstant.LOADING_FALSE));
          dispatch(request(dashboardConstant.DOWNLOAD_DASHBOARD_SUCCESS))
        }
      }, error => {
        dispatch(request(uiConstant.LOADING_FALSE));
      })
    } else {
      dispatch(failure(alertConstant.ALERT_ERROR, "Report currently loading. Please try again once your report updates."));
    }
  }
}

/**
 * download dashboard report PPT
 */
function downloadDashboardReportPPT(includeDashboard, reportType = "Dashboard Report", dashboardDownload, isLoading,client_logo,filters ) {
  return (dispatch, getState) => {
    const dashboardData = getState().dashboard;
    const filterData = getState().filter?.filterData;
    const { admin: { preference: { reportVisibility } }, filter: {
      filterData: { surveys }
    } } = getState();
    const hasMESurvey = surveys.filter(s => s.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0
    const chartsList = Object.keys(hasMESurvey ? ReportsConstants.CHARTS_ME : ReportsConstants.CHARTS)

    let visibilityKeys = Object.keys(reportVisibility || {}).filter(k => chartsList.indexOf(k) > -1)
    let iscommentDashboard = false
    if (reportType === "Dashboard Report Comment") {
      iscommentDashboard = true
      visibilityKeys = ["1"]
    }
    const { highlevelSummary: { loading: hlsLoader },
      commentAnalysis: { loading: commentLoader },
      yesNo: { loading: yennoLoader },
      gridChart: { loading: gridLoader },
      hotspot: { loading: hotspotLoader },
      engagementIndex: { loading: engagementIndexLoader },
      trendsOverTime: { loading: TOTLoader },
      competency: { loading: CompetencyLoader } } = dashboardData
    const loaders = { hlsLoader, commentLoader, yennoLoader, gridLoader, hotspotLoader, engagementIndexLoader, TOTLoader, CompetencyLoader }
    let downloadReport = true;
    let IsDashboardDownload = false;
    if (!dashboardDownload) {
      Object.keys(loaders).map((key, index) => {
        if (loaders[key] === true && visibilityKeys.indexOf(`${index}`) !== -1) {
          downloadReport = false
        }
      })
    } else if (isLoading) {
      dispatch(failure(alertConstant.ALERT_ERROR, "Dashboard currently loading. Please try again once your dashboard updates."));
      return
    } else {
      IsDashboardDownload = true
    }
    if (downloadReport) {
      const data = {
        pdfType: "Dashboard Report",
        includeDashboard,
        dashboardData,
        iscommentDashboard,
        IsDashboardDownload,
        client_logo,
        filterData,
        filters
      }
      // dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(dashboardConstant.DOWNLOAD_DASHBOARD_REQUEST))
      dashboardService.downloadDashboardReportPPT(data).then((data) => {
        if (data && data.data && (data.data.error || data.status === "failed")) {
          dispatch(failure(alertConstant.ALERT_ERROR, data.data.error));
          dispatch(request(dashboardConstant.DOWNLOAD_DASHBOARD_FAILURE))
          dispatch(request(uiConstant.LOADING_FALSE));
        } else {
          const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
          saveAs(blob, data.filename);
          dispatch(success(alertConstant.ALERT_SUCCESS, "Successfully downloaded"));
          dispatch(request(uiConstant.LOADING_FALSE));
          dispatch(request(dashboardConstant.DOWNLOAD_DASHBOARD_SUCCESS))
        }
      }, error => {
        dispatch(request(uiConstant.LOADING_FALSE));
      })
    } else {
      dispatch(failure(alertConstant.ALERT_ERROR, "Report currently loading. Please try again once your report updates."));
    }
  }
}

/**
 * Competency Summary
 */
function getCompetencySummary(successCallback = () => { }) {
  return dispatch => {
    dispatch(request(dashboardConstant.GET_COMPETENCY_REQUEST));
    dashboardService.getCompetencySummary().then(resp => {
      dispatch(success(dashboardConstant.GET_COMPETENCY_SUCCESS, resp));
      successCallback()
    }, error => {

    });
  };
}


/**
 * Competency Summary
 */
function resetChartData(data) {
  return dispatch => {
    dispatch(request(dashboardConstant.RESET_CHART_DATA_REQUEST, data));
  };
}

// Reset Data Filters Applied Flag
function resetDataFiltersAppliedFlag() {
  return dispatch => {
    dispatch(request(dashboardConstant.RESET_DATA_FILTERS_APPLIED));
  }
}

/**
 * Get Key Driver Widget Data
 * @param {*} successCallback 
 */
function getKeyDriverData(successCallback = () => { }, loader = true) {
  return dispatch => {
    if (loader) {
      dispatch(request(uiConstant.LOADING_TRUE));
    }
    dispatch(request(dashboardConstant.GET_KEY_DRIVER_REQUEST));
    dashboardService.getKeyDriverData().then(resp => {
      dispatch(success(dashboardConstant.GET_KEY_DRIVER_SUCCESS, resp));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback()
    }, error => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(dashboardConstant.GET_KEY_DRIVER_FAILURE, error));
    });
  };
}

// Flag to show and hide comment analysis chart
function updateCommentChartStatus(data) {
  return {
    type: dashboardConstant.UPDATE_COMMENT_CHART_STATUS,
    data: data
  };
};

// reset dashboard loader
function resetDashboardLoader() {
  return {
    type: dashboardConstant.RESET_DASHBOARD_LOADER
  };
};

// add dashboard request count
function addDashboardRequestCount(data) {
  return {
    type: dashboardConstant.ADD_DASHBOARD_REQUEST_COUNT,
    data: data
  };
};

// increment dashboard request count
function incrementDashboardRequestCount() {
  return {
    type: dashboardConstant.INCREMENT_DASHBOARD_REQUEST_COUNT
  }
}

// update dashboard loader
function updateDashboardLoader() {
  return {
    type: dashboardConstant.UPDATE_DASHBOARD_LOADER
  }
}

// Apply FIlters to HLS and Hotspot
function applyFilters(successCallback = () => { }, loader = true, chartType, data) {
  return dispatch => {
    if (loader) {
      dispatch(request(uiConstant.LOADING_TRUE));
    }
    
    const chartTypeConst = chartType === 'hls' ? dashboardConstant.APPLY_HLS_FILTERS : dashboardConstant.APPLY_HOTSPOT_FILTERS;
    dispatch(success(chartTypeConst, data));
    dispatch(request(dashboardConstant.RESET_DATA_FILTERS_APPLIED));
    successCallback();

    if (loader) {
      dispatch(request(uiConstant.LOADING_FALSE));
    }
  }
};