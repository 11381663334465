import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

//import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import LineChartComponent from "../../../../components/Charts/YesNoLineChart/YesNoLineChart"
import YNOverallChart from "./YesNoOverallChart";

function YesNoPopup(props) {

  const { classes, chartData, selectedValue, selectedValueIndex } = props;

  // custom demographics sort function
  const demographicsSortFunction = (a, b) =>{
    const meanRemovedDemographic_a = a.replace(/\(\d+\)$/g, "")
    const meanRemovedDemographic_b = b.replace(/\(\d+\)$/g, "")
    if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue]){
      if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_a] !== undefined && ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_b] !== undefined){
        if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_a] > ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_b]){
          return 1
        }
        else if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_a] < ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_b]){
          return -1
        }
      }
      else{
        if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_a] === undefined){
          return 1
        }else if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_b] === undefined){
          return -1
        }
        else{
          return 0
        }
      }
    }
    else{
      return 0
    }
  }

  return (
    <React.Fragment>
      {chartData && selectedValue !== "no_breakout" && Object.keys(chartData).length > 0 && Object.keys(chartData).map((item, index) => (
        <Grid item sm={12}>
          {item !== "demographics" &&
            <Grid container>
              <Grid className={"ml-1 mr-15"}>
                <span className="dotyesnochart"></span>
              </Grid>
              <Grid item sm={11}>
                <TypographyComponent
                  title={`${chartData[item].OverallYesNo.questionText} (N=${chartData[item].OverallYesNo.participantCount})`}
                  variant={"p6"}>
                </TypographyComponent>
              </Grid>
              <Grid item sm={12}>
                <Grid container>
                  <Grid container className="justifycenter mt-2">
                    <Grid className={classes.iconRow}>
                      <Grid className={classes.iconContainer}>
                        <Grid className={classes.YesLabelButton} />
                        <Grid className="fontSize14 txtgrey mr-15">{chartData?.[item]?.ansSet?.en?.[0] ? chartData[item].ansSet.en[0] : "Yes"}</Grid>
                        <Grid className={classes.NoLabelButton} />
                        <Grid className="fontSize14 txtgrey">{chartData?.[item]?.ansSet?.en?.[1] ? chartData[item].ansSet.en[1] : "No"}</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} className={"txtcenter"}>
                    <YNOverallChart
                      data={chartData[item]}
                      index={`popup${index}`}
                    />
                  </Grid>
                  {selectedValue !== "" &&
                    <Grid item sm={8} className={"pt-2"}>
                      {Object.keys(chartData[item][selectedValue] || {}).sort(demographicsSortFunction).map((item1, index1) => (
                        <Grid item sm={12}>
                          {item1 !== "OverallYesNo" &&
                            <Grid container className="pb-2">
                              <Grid item sm={3}>
                                <TypographyComponent
                                  variant={"p6"}>{`${['Yes', 'No'].indexOf(item1) !== -1 && chartData?.[item]?.ansSet?.en?.[item1.toLowerCase() === 'yes' ? 0 : 1] ? chartData?.[item]?.ansSet?.en?.[item1.toLowerCase() === 'yes' ? 0 : 1] : item1} (${chartData[item][selectedValue][item1].participantCount})`}
                                </TypographyComponent>
                              </Grid>
                              <Grid item sm={1}>
                                <TypographyComponent variant="h5" className="fontSize14">{`${Math.round((chartData[item][selectedValue][item1].yes / chartData[item][selectedValue][item1].response) * 100)}%`}</TypographyComponent>
                              </Grid>
                              <Grid item sm={4}>
                                <LineChartComponent
                                  height={"30px"}
                                  data={Math.round((chartData[item][selectedValue][item1].yes / chartData[item][selectedValue][item1].response) * 100)}
                                  chartId={`popupline${index}${selectedValueIndex}${index1}chart`}
                                />
                              </Grid>
                              <Grid item sm={1}>
                                <TypographyComponent variant="h5" className="fontSize14">{`${Math.round((chartData[item][selectedValue][item1].no / chartData[item][selectedValue][item1].response) * 100)}%`}</TypographyComponent>
                              </Grid>
                            </Grid>
                          }
                        </Grid>
                      ))}
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      ))}
      {(!chartData || Object.keys(chartData).length === 0 && (chartData.demographics && chartData.demographics.length === 0)) && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="mt-3"
        >
          <TypographyComponent variant="h3">
            {ReportsConstants.NO_DATA_CHART}
          </TypographyComponent>
        </Grid>
      )}
      { selectedValue === "no_breakout" && Object.keys(chartData).map((item, index) => (
        <Grid item sm={12}>
          {item !== "demographics" &&
            <Grid container>
              <Grid item sm={7} className={"mt-6"}>
                <TypographyComponent
                  title={`${chartData[item].OverallYesNo.questionText} (N=${chartData[item].OverallYesNo.participantCount})`}
                  variant={"p6"}>
                </TypographyComponent>
              </Grid>
              <Grid item sm={5}>
                <Grid container>
                  <Grid item sm={12} className={"txtcenter"}>
                    <YNOverallChart
                      data={chartData[item]}
                      index={`popup${index}`}
                    />
                  </Grid>
                  <Grid container className="justifycenter mb-4 mt-2">
                    <Grid className={classes.iconRow}>
                      <Grid className={classes.iconContainer}>
                        <Grid className={classes.YesLabelButton} />
                        <Grid className="fontSize14 txtgrey mr-15">{chartData?.[item]?.ansSet?.en?.[0] ? chartData[item].ansSet.en[0] : "Yes"}</Grid>
                        <Grid className={classes.NoLabelButton} />
                        <Grid className="fontSize14 txtgrey">{chartData?.[item]?.ansSet?.en?.[1] ? chartData[item].ansSet.en[1] : "No"}</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      )
      )}
    </React.Fragment>
  );
}
// default props
YesNoPopup.defaultProps = {
  classes: {},
  chartData: {},
  selectedValue: "",
  selectedValueDemoItem: "",
  selectedValueIndex: ""
};

// prop types
YesNoPopup.propTypes = {
  classes: PropTypes.object,
  chartData: PropTypes.object,
  selectedValue: PropTypes.string,
  selectedValueDemoItem: PropTypes.string,
  selectedValueIndex: PropTypes.any
};
export default withStyles(ReportsStyles)(YesNoPopup);
