import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from '@mui/material/CircularProgress';
import { VariableSizeList as VList } from 'react-window';

// Import Styles
import FilterStyles from "../FilterStyles";

// Import Image
// import SearchImg from '../../../assets/images/svg/search.svg';
import CloseIcon from '@mui/icons-material/Close';
import { surveyTypes } from "../../../config/constants";
// Import Constants
import { FilterConstants } from '../../../config/constants';

// Import Reducer Constant
import { filterConstant, dashboardConstant } from '../../../redux/constants';

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import InformationComponent from "../../../components/InformationComponent/InformationComponent";
import SearchInputComponent from "../../../components/SearchInputCompoment/SearchInputComponent";

//Import Actions
import { filterAction, updateStateReducerAction } from '../../../redux/actions'

// Import Support Container
import DemographicFilterItem from "./DemographicFilterItem";

function DemographicFilterPanel(props) {

    // Set State
    const [search, setSearch] = useState("")
    const [expanded, setExpanded] = useState(null);
    const [demographicHeights, setDemographicHeights] = useState({});
    const listRef = useRef(null);

    // Get Props
    const { classes, preference, parentExpanded, handlePanel, panelHeight } = props;
    let survey = []

    // Define Dispatch
    const dispatch = useDispatch();

    /**
    * Get Redux Values
    */
    const { demographics, filterData, demographicsLoading, allSurveys, demographicItems } = useSelector(state => state.filter)
    const { metadata } = useSelector(state => state.metadata)
    const demoMeta = {}
    metadata.map(meta => {
        demoMeta[meta.key] = meta.value
    })

    // To check if the surveys met survey threshold value
    const surveysId = []
    filterData.surveys.map((val) => {
        surveysId.push(val._id)
    })
    let validSurveys = []
    if (allSurveys) {
        validSurveys = allSurveys.filter((sur) => surveysId.includes(sur._id))
    }

    // Get demographics Data
    useEffect(() => {
        if (parentExpanded === "demographic-panel") {
            dispatch(filterAction.getDemographics({
                metadata: [],
                surveys: validSurveys && validSurveys.length > 0 ? filterData.surveys : [],
                date: filterData.date
            }))
        }
    }, [filterData.surveys, filterData.date, parentExpanded])

    // Collapse Demographic Itesm
    useEffect(() => {
        if (parentExpanded !== "demographic-panel") {
            setExpanded(null)
        }
    }, [parentExpanded])

    // Update list when items change
    useEffect(() => {
        if (listRef?.current) {
            listRef?.current?.resetAfterIndex?.(0);
        }
    }, [expanded, demographicItems]);

    // Update height calculations when expanded demographic changes
    useEffect(() => {
        updateDemographicHeights();
    }, [expanded, demographicItems]);

    // Calculate heights for each demographic item
    const updateDemographicHeights = () => {
        const newHeights = {};
        if (demographics) {
            Object?.keys?.(demographics)?.forEach?.(key => {

                const baseHeight = 40; // Base height for the accordion header

                // Add extra height if this demographic is expanded
                if (expanded === key && demographicItems?.[key]) {
                    // Calculate inner list height (limited to 300px max as in DemographicFilterItem)
                    const itemCount = demographicItems?.[key]?.length || 0;
                    const innerListHeight = Math?.min?.(itemCount * 40, 270);
                    newHeights[key] = baseHeight + innerListHeight + 10; // Add some padding
                } else {
                    newHeights[key] = baseHeight;
                }
            });
        }
        setDemographicHeights(newHeights);
    };

    //Accordion 
    const handlePanelChange = (panel) => {
        const isExpanded = panel === expanded ? null : panel
        setExpanded(isExpanded);
        if (isExpanded) {
            dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_INITIAL_RELOAD, "all", () => {
                dispatch(filterAction.demographicsByName({
                    metadata: [],
                    surveys: validSurveys && validSurveys.length > 0 ? filterData.surveys : [],
                    date: filterData.date,
                    demographic: isExpanded,
                    search
                }))
            }))
        }
    };

    // Calculate item size for variable list
    const getItemSize = (index) => {
        const demographicsValue = demographics ? Object?.keys?.(demographics) : [];
        if (demographicsValue?.length > 0) {
            const key = demographicsValue?.[index];
            return demographicHeights?.[key] || 40; // Default height if not calculated yet
        }
        return 40; // Default height
    };

    // Render each demographic filter item
    const DemographicRow = ({ index, style }) => {
        const demographicsValue = demographics ? Object?.keys?.(demographics) : [];

        if (demographicsValue?.length > 0) {
            const key = demographicsValue?.[index];

            if (demographics?.[key]) {
                return (
                    <div style={style}>
                        <DemographicFilterItem
                            demographicData={demographics?.[key]}
                            demographicKey={key}
                            preference={preference}
                            metadata={metadata}
                            handlePanelChange={handlePanelChange}
                            expanded={expanded}
                        />
                    </div>
                );
            }
        }
        return null;
    };

    /** 
     * Handle Search 
     */
    const handleSearch = (name, value, userChange) => {
        if (userChange) {
            dispatch(filterAction.getDemographics({
                metadata: [],
                surveys: validSurveys && validSurveys.length > 0 ? filterData.surveys : [],
                date: filterData.date,
                search: value
            }))
            setSearch(value)
        }
    }

    // on clear search
    const handleClearSearch = () => {
        setSearch('')
        dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_INITIAL_RELOAD, "all", () => {
            dispatch(filterAction.getDemographics({
                metadata: [],
                surveys: validSurveys && validSurveys.length > 0 ? filterData.surveys : [],
                date: filterData.date
            }))
        }))
    }

    // Clear Filter 
    const handleClear = (e) => {
        e.stopPropagation();
        filterData.demographics = {};
        dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_INITIAL_RELOAD, "all", () => {
            dispatch(updateStateReducerAction.update(filterConstant.UPDATE_FILTER_SUCCESS, filterData))
        }))
    }

    /**
     * Bind Selected Demographics as Chips 
     */
    const bindSelectedDemogrphics = () => {
        if (filterData && filterData.demographics) {
            return Object.keys(filterData.demographics).map((key) => {
                return filterData.demographics[key].map((item, index) => {
                    return <Tooltip title={item} arrow>
                        <Chip
                            key={`${item}_${index}`}
                            name={item}
                            label={`${demoMeta[key]} ${item}`}
                            classes={{
                                root: classes.demographicsChips
                            }}
                            onDelete={e => removeSelectedDemogrphicsItem(key, item)}
                            deleteIcon={<CloseIcon className={classes.demogrpahicsChipClose} />}
                        />
                    </Tooltip>

                })
            })
        }
    }

    /**
     * Remove Selected DemographicsItem
     */
    const removeSelectedDemogrphicsItem = (key, item) => {
        filterData.demographics[key].splice(filterData.demographics[key].indexOf(item), 1)
        // delete the key id it has 0 length
        if (filterData.demographics[key] && filterData.demographics[key].length === 0) {
            delete filterData.demographics[key]
        }
        dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_INITIAL_RELOAD, "all", () => {
            dispatch(updateStateReducerAction.update(filterConstant.UPDATE_FILTER_SUCCESS, filterData))
        }))
    }
    
    const surveyType = filterData.surveys.length > 0 ? filterData.surveys[0].type : ""
    return (
        <React.Fragment>
            {surveyType !== "Multi-rater feedback" &&
                <Accordion
                    classes={{
                        root: classes.peaccordion_nocolor
                    }}
                    expanded={parentExpanded === 'demographic-panel'}
                    onChange={handlePanel('demographic-panel')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Grid container justifyContent="space-between">
                            <Grid className="dflex">
                                <TypographyComponent variant={"h6"}
                                    className={`${"txtBold "}  ${parentExpanded === 'demographic-panel' ? "panel-active" : ""} `}>
                                    {FilterConstants.DEMOGRAPHICS}
                                </TypographyComponent>
                                <InformationComponent>
                                    <TypographyComponent variant="tooltipbody">
                                        {FilterConstants.DEMOGRAPHICS_HELP}
                                    </TypographyComponent>
                                </InformationComponent>
                            </Grid>

                            <Link
                                className={`cursor-pointer colorTheme right ${classes.dataFiltersClearAll}`}
                                onClick={e => handleClear(e)}
                            >
                                {FilterConstants.CLEAR_ALL}
                            </Link>
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container className={`${classes.expansionDetail}`}>
                            <Grid item xs={12} className={'mb-1'}>
                                {bindSelectedDemogrphics()}
                            </Grid>
                            <Grid item xs={12} className="relative pb4">
                                <SearchInputComponent
                                    id="searchDemoText"
                                    placeholder={"Search"}
                                    value={search}
                                    field={"search"}
                                    showClearIcon={true}
                                    fullWidth={true}
                                    className={`${classes.demographySearch}`}
                                    onInputChangeRequest={handleSearch}
                                    handleClearSearch={() => handleClearSearch}
                                    onEnter={() => { }} />
                            </Grid>
                            {demographicsLoading
                                ? <Grid item xs={12} className={`txtcenter p-3`}>
                                    <CircularProgress size={30} />
                                </Grid>
                                : <Grid item xs={12}>
                                    {demographics && Object?.keys?.(demographics)?.length > 0 ? (
                                        <VList
                                            ref={listRef}
                                            height={panelHeight / 2}
                                            itemCount={Object?.keys?.(demographics)?.length}
                                            itemSize={getItemSize}
                                            width={344}
                                        >
                                            {DemographicRow}
                                        </VList>
                                    ) : (
                                        <Grid item xs={12} className={"mt-1"}>
                                            <TypographyComponent title='No demographics found!' variant='inherit' />
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
        </React.Fragment>
    )
}

// default props
DemographicFilterPanel.defaultProps = {
    classes: {},
    panelHeight: 0,
    preference: {},
    parentExpanded: null,
    handlePanel: () => { }
};

// prop types
DemographicFilterPanel.propTypes = {
    classes: PropTypes.object,
    panelHeight: PropTypes.number,
    preference: PropTypes.object
};

export default withStyles(FilterStyles)(DemographicFilterPanel);